const initialState = {
    list: [],
    error: {},
    industryStages:{}
};

const IndustryStagesReducers = (state, action) => {

    if (state === null || state === undefined) {
        state = initialState;
    }

    switch (action.type) {
       
        case "GET_INDUSTRY_STAGES_ERROR":
            state = {
                ...state,
                industryStages: [],
                error: action.payload
            };
            return state;

        case "GET_INDUSTRY_STAGES_SUCCESS":
            state = {
                ...state,
                industryStages: action.payload
            };
            return state;
        case "DELETE_INDUSTRY_STAGES_SUCCESS":
            state = {
                ...state,
                industryStages: {
                    stages :action.payload
                }
            };
        return state;
        default:
            return state;
    }
};

export default IndustryStagesReducers;
