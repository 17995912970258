import React, {Fragment} from 'react';

import {Col, Row, Switch,} from 'react-materialize';
import {Tab, TabList, TabPanel, Tabs,} from 'react-tabs';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    INDUSTRY_DIMENSION_VALUE,
    INDUSTRY_DIMENSION_VALUE_GRID,
    SOURCE_INDUSTRY,
} from '../../../constants/appConstants';
import {trimCapitalize, validateCustomPattern,} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal, TatvamTextBox,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import IndustryDimensionValueForm from '../industryDimensionValueForm';
import IndustryStages from '../tabsProject/tabs/industryStages';
import IndustryTouchpoints from '../tabsProject/tabs/industryTouchpoints';
import IndustryDimensionValues from '../tabsProject/tabs/industryDimensionValues';

class IndustryDimensionValuesDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editFlag: "",
            isActive: false,
            xPathGridData: [{name: "Loading.."}],
            xPathAttributeDetailsGridData: [],
            colDef: [
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.ENTITY_TYPE,
                    field: "parent_entity_value",
                    filterParams: {clearButton: true},
                    width: 227
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.ID,
                    field: "_id",
                    filterParams: {clearButton: true},
                    hide: true
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.ENTITY_VALUE,
                    field: "entity_value",
                    filterParams: {clearButton: true},
                    width: 182
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    width: 108
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.PATTERN,
                    field: "pattern",
                    filterParams: {clearButton: true},
                    width: 145
                }
            ],
            name: "",
            idSource: "",
            nameSource: "",
            sleepTime: "",
            statusSource: "",
            urlSource: "",
            totalActive: 0,
            totalRecords: 0,
            totalAttrDetails: 0,
            stagesActiveCount:0,
            stagesTotalCount:0,
            touchpointActiveCount:0,
            touchpointTotalCount:0,
            dimensionActiveCount:0,
            dimensionTotalCount:0
        };
    }

    _loadIndustryDimensionData = (thisProps, industry_name) => {
        thisProps.actions.getDimensionValuesByIndustryName(industry_name).then(() => {
            this.setState({
                loader: false
            });
        });
    }

    componentDidMount() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({inValidForm: true});
                }, true);
        }
        const industry_name = this.props.history.location.state.industryData.name;
        this.setState({
            loader: true
        });

        this._loadIndustryDimensionData(this.props, industry_name);

        if (this.props.history.location.state.industryData) {
            this.setState({
                industry_name: this.props.history.location.state.industryData.name,
                industry_id: this.props.history.location.state.industryData.id,
                status: this.props.history.location.state.industryData.status,
                applicationMode: "VIEW",
                header_name: this.props.history.location.state.industryData.name,
                isActive: trimCapitalize(this.props.history.location.state.industryData.status) === APPLICATION_MODES.ACTIVE,
                sortColumnName: this.props.history.location.state.sortColumnName,
                sortOrderName: this.props.history.location.state.sortOrderName,
                returnPath: this.props.history.location.state.returnPath
            });
        }
    }

    componentWillReceiveProps(props) {
        let activeCount = 0;

        if (props.history.location.state.industryData) {
            this.setState({
                industry_name: props.history.location.state.industryData.name,
                industry_id: props.history.location.state.industryData.id,
                status: props.history.location.state.industryData.status,
                isActive: trimCapitalize(props.history.location.state.industryData.status) === APPLICATION_MODES.ACTIVE,
                sortColumnName: this.props.history.location.state.sortColumnName,
                sortOrderName: this.props.history.location.state.sortOrderName,
                returnPath: this.props.history.location.state.returnPath
            });
        }
        let industryDimensionValuesGridDatas = [];
        if (props.gridData) {
            for (let data of Object.values(props.gridData)) {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        if (status === APPLICATION_MODES.ACTIVE) {
                            activeCount++;
                        }
                        industryDimensionValuesGridDatas.push(data);
                    }

                }
            }
            this.setState({
                totalActive: activeCount,
                totalRecords: props.gridData ? Object.values(props.gridData).length : 0
            });
        }
        this.setState({
            industryDimensionValuesGridData: industryDimensionValuesGridDatas
        });
    }

    _onEditRowClick = (data) => {
        this.setState({
            dimensionValueData: data[0],
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };


    _handleInput = e => {
        if (e.target.id === "status") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;

            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        } else if (e.target.name === "industry_name") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^[a-zA-Z0-9_ ]{1,255}$/g)) {
                return false;
            }

        }
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                [e.target.name]: true
            }
        });

    };

    _triggerDiscard = e => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                notDiscard: false
            });
        } else {
            this.setState({
                applicationMode: APPLICATION_MODES.VIEW,
            });

        }
    };

    _openCreateModal = () => {
        this.setState({
            dimensionValueData: [],
            modalTitle: INDUSTRY_DIMENSION_VALUE.ADD_INDUSTRY_DIMENSION_VALUE,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true,
            industryName: this.state.industry_name

        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    _goBack = () => {
        this.props.history.push({
            pathname: this.state.returnPath,
            state: {
                sortColumnName: this.props.history.location.state.sortColumnName,
                sortOrderName: this.props.history.location.state.sortOrderName
            }
        });
    };

    _handleSave = e => {
        e.preventDefault();
        let {industry_name, industry_id, status} = this.state;
        let industryData = {
            name,
            industry_id,
            status
        };

        if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
            industryData = {
                ...this.props.formData
            };
            industryData.name = industry_name;
            industryData.industry_id = industry_id;
            industryData.status = status;
            industryData.updated_by = this.props.userName;
            industryData.old_name = this.props.history.location.state && this.props.history.location.state.industryData ? this.props.history.location.state.industryData.name : "";
            this.setState({
                preLoader: true
            });
            this.props.actions.updateIndustry(industryData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message,

                    });

                    const industryData = this.props.history.location.state.industryData;
                    industryData.name = industry_name;
                    industryData.status = status;
                    const customSortColumn = this.props.history.location.state.sortColumnName;
                    const customSortOrder = this.props.history.location.state.sortOrderName;

                    this.props.history.push({
                        pathname: "/industryDimensions",
                        state: {
                            industryData: industryData,
                            sortColumnName: customSortColumn,
                            sortOrderName: customSortOrder,
                            returnPath: "/industry"
                        }
                    });

                    this._loadIndustryDimensionData(this.props, this.state.industry_name);

                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
    };

    _discard = (data) => {
        if (data === "ok") {
            this.closeModal();
            this.setState({
                applicationMode: this.state.applicationMode === APPLICATION_MODES.EDIT ? APPLICATION_MODES.VIEW : APPLICATION_MODES.VIEW,
                editFlag: "",
                industry_name: this.props.history.location.state.industryData.name,
                status: this.props.history.location.state.industryData.status,
                isActive: trimCapitalize(this.props.history.location.state.industryData.status) === APPLICATION_MODES.ACTIVE
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleDeleteConfirmation = (data) => {
        this.validateIfIndustryInUse(data).then((returnRes) => {
            if (returnRes) {
                this.setState({
                    idIndustryDimension: data._id,
                    name: data.name,
                    isConfirmBox: true,
                    isAlertBoxOpen: false,
                    isDeleteAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION,
                    applicationMode: APPLICATION_MODES.VIEW
                });
            }
        });
    };

    validateIfIndustryInUse = (industry) => {
        const industryInUse = Object.values(this.props.customers).filter(customer => customer.industry === industry.industry_name);
        if (industryInUse.length > 0) {
            this.setState({
                idIndustryDimension: industry._id,
                name: industry.name,
                isConfirmBox: true,
                isAlertBoxOpen: false,
                isDeleteAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.INDUSTRY_EXIST
            });
            return Promise.resolve(false);

        }
        return Promise.resolve(true);
    };

    _deleteRow = (data) => {
        if (data === "ok") {
            let industry_dimension_id = this.state.idIndustryDimension;
            let industryDimensionData = {
                industry_dimension_id

            };
            this.props.actions.deleteIndustryDimension(industryDimensionData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.props.actions.getDimensionValuesByIndustryName(this.state.industry_name).then(() => {

                    });

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }

    _handelStageCount=(stagesTotalActiveCount,stagesCount)=>{
        this.setState({
            stagesActiveCount:stagesTotalActiveCount,
            stagesTotalCount:stagesCount
        });
    }

    _handelTouchpointCount=(touchpointTotalActiveCount,touchpointCount)=>{
        this.setState({
            touchpointActiveCount:touchpointTotalActiveCount,
            touchpointTotalCount:touchpointCount
        });
    }

    _handelDimensionValuesCount=(dimensionTotalActiveCount,dimensionCount)=>{
        this.setState({
            dimensionActiveCount:dimensionTotalActiveCount,
            dimensionTotalCount:dimensionCount
        });
    }

    render() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({inValidForm: true});
                }, true);
        }

        return (
            <Row className="create-project-page">
                <Row>
                    <Row>
                        <Col className="right">
                            <TatvamButton
                                waves="light"
                                onClick={this._goBack}
                                className="goBackBtn btn_primary"
                                title="go back "
                            >
                                <i className="material-icons">arrow_back</i>
                            </TatvamButton>
                        </Col>
                        <TatvamCol className="z-depth-2 centered mt-2 form-wrapper" s={12} m={12} l={12} xl={12}>
                            <div className="page_heading mt-0 mb-1 ml-1">
                                {/* {<h5>{this.state.header_name}</h5>} */}
                                <h5>{this.state.applicationMode === APPLICATION_MODES.VIEW ? "View Industry" : "Edit Industry"}</h5>
                            </div>
                            {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                                    <Row className="d-flex justify-around">
                                        <Col s={12} m={12} l={11} className="ml-2">
                                            <form onSubmit={this._handleSave}>

                                                <TatvamCol s={12} className="formRow  mt-2">
                                                    <TatvamTextBox
                                                        className="labelText mt-0 project_name validate"
                                                        m={6}
                                                        l={4}
                                                        label={SOURCE_INDUSTRY.ID}
                                                        name="industry_id"
                                                        required
                                                        onChange={this._handleInput}
                                                        value={this.state.industry_id}
                                                        disabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                                        autocomplete="off"
                                                        maxLength={10}
                                                    />
                                                    <TatvamTextBox
                                                        className={(this.state.inValidForm && !this.state.industry_name) ? "labelText mt-0 validate project_name mb-4 invalid" : "labelText mt-0 validate project_name mb-4"}
                                                        m={6}
                                                        l={4}
                                                        label={
                                                            <span>
                              {SOURCE_INDUSTRY.NAME}
                                                                <span style={{color: 'red'}}> *</span>
                            </span>
                                                        }
                                                        name="industry_name"
                                                        required
                                                        aria-required={true}
                                                        onChange={this._handleInput}
                                                        value={this.state.industry_name}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        autocomplete="off"
                                                        maxLength={255}
                                                    />

                                                    <Switch
                                                        id="status"
                                                        checked={this.state.isActive}
                                                        onChange={this._handleInput}
                                                        offLabel={SOURCE_INDUSTRY.STATUS}
                                                        m={2}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />

                                                    <button type="submit" ref="submitForm" className="hide"/>
                                                </TatvamCol>
                                                <TatvamCol s={12} className="p-0">
                                                    {this.state.applicationMode !== APPLICATION_MODES.VIEW && (
                                                        <Fragment>
                                                            <TatvamCol s={12} m={6}
                                                                       className="buttonWrapper right valign-wrapper formRow">
                                                                <TatvamButton
                                                                    waves="light"
                                                                    className="mt-1 mr-3 btn_primary waves"
                                                                    onClick={this._triggerDiscard}
                                                                >
                                                                    {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                                                        FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                                                                </TatvamButton>

                                                                <TatvamButton
                                                                    waves="light"
                                                                    className=" mt-1 mr-1 btn_primary  "
                                                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                                                >
                                                                    {FORM_OPERATIONS.SAVE}
                                                                </TatvamButton>
                                                            </TatvamCol>
                                                        </Fragment>
                                                    )}
                                                </TatvamCol>
                                            </form>
                                        </Col>
                                    </Row>
                                ) :
                                (
                                    <Fragment>
                                        <TatvamCol s={12} m={12} l={2} className="imageWrapper hide">
                                            <div className="left d-flex flex-colum image-uploaded mb-2">
                                                <img
                                                    className="responsive-img"
                                                    src={this.state.logoSource ? this.state.logoSource :
                                                        require("../../../../public/assets/images/default_customer.png")}
                                                    alt="logo"
                                                    title="Property Logo"
                                                />
                                            </div>
                                        </TatvamCol>
                                        <TatvamCol s={12} l={12} className="p-0 customerFormView">

                                            <TatvamCol s={12} m={12} class="mt-.75">
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6">{INDUSTRY_DIMENSION_VALUE_GRID.ID} : </span>
                                                    <span className="col s6"> {this.state.industry_id}</span>
                                                </div>
                                                {/* <div className="mt-0 col s12 m4">
                      <span className="property-label col s6"> {SOURCE_XPATH.SOURCE_NAME}: </span>
                      <span className="col s6 propertyNameView"> {this.state.nameSource} </span>
                    </div> */}

                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {INDUSTRY_DIMENSION_VALUE_GRID.NAME} : </span>
                                                    <span
                                                        className="col s6 url-wrap"> {this.state.industry_name} </span>
                                                </div>

                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {INDUSTRY_DIMENSION_VALUE_GRID.STATUS} : </span>
                                                    <span className="col s6"> {this.state.status} </span>
                                                </div>
                                            </TatvamCol>

                                            {/* <TatvamCol s={12} m={12} className="mt-.75">
                    <div className="mt-0 col s12 m4">
                      <span className="property-label col s6"> {SOURCE_XPATH.VIEW_URL} : </span>
                      <span className="col s6"> {this.state.urlSource} </span>
                    </div>
                  </TatvamCol> */}

                                            <TatvamCol className="right">
                                                {this.props.industryDimensionPermission === APP_BOOLEANS.TRUE && (
                                                    <TatvamButton
                                                        className="mt-1 CreateProjectPublish btn_primary "
                                                        name="EDIT"
                                                        onClick={this._handleAppMode}
                                                    >
                                                        {FORM_OPERATIONS.EDIT}
                                                    </TatvamButton>
                                                )}
                                            </TatvamCol>
                                        </TatvamCol>

                                    </Fragment>
                                )}
                        </TatvamCol>
                    </Row>
                    <Row>
                        <Col className="z-depth-2 col-centered mt-3 mb-3 p-0 tabsContainer" s={12} m={12} l={12}
                             xl={12}>
                            <div class={this.state.applicationMode === "EDIT" ? "disabledTabs" : ""}></div>
                            <div className="row m-0">
                                <Tabs>
                                    <TabList className="tabs customTabs z-depth-2 tabs-fixed-width">
                                        {/* <Tab className="tab">{INDUSTRY_DIMENSION_VALUE_GRID.DETAILS}<span
                                            class="activeCountSpan">({this.state.totalActive}/{this.state.totalRecords})</span></Tab> */}

                                        <Tab className="tab">{INDUSTRY_DIMENSION_VALUE_GRID.STAGES}<span
                                            class="activeCountSpan">({this.state.stagesActiveCount}/{this.state.stagesTotalCount})</span></Tab>

                                        <Tab className="tab">{INDUSTRY_DIMENSION_VALUE_GRID.TOUCHPOINTS}<span
                                            class="activeCountSpan">({this.state.touchpointActiveCount}/{this.state.touchpointTotalCount})</span></Tab>
                                        

                                        <Tab className="tab">{INDUSTRY_DIMENSION_VALUE_GRID.DIMENSION_VALUES}<span
                                            class="activeCountSpan">({this.state.dimensionActiveCount}/{this.state.dimensionTotalCount})</span></Tab>
                                            
                                    </TabList>

                                    {/* <TabPanel> */}
                                        {/* <div className=""> */}
                                            {/* { <h5>{SOURCE_XPATH.WEB_SOURCE_XPATH}</h5> } */}
                                            {/* <div className="dashboard-btn mt-1 valign-wrapper"> */}
                                            {/*{this.props.xPathCreate === "true" && (*/}
                                            {/* <div className="mt-2 ml-2 mr-2 left "><h5>Active {this.state.totalActive}/{this.state.totalRecords}</h5></div> */}
                                            {/* <TatvamButton className="mt-2 ml-2 mr-2 right btn_primary"
                                                          onClick={this._openCreateModal} title="Add Xpath">
                                                {INDUSTRY_DIMENSION_VALUE.ADD_INDUSTRY_DIMENSION_VALUE}
                                            </TatvamButton> */}
                                            {/*)*/}
                                            {/*}*/}
                                            {/* </div> */}
                                        {/* </div> */}


                                        {/* <Col s={12} className="roleGrid">
                                            <div className="row  z-depth-4 h-75" ref="tableTop">
                                                <TatvamGrid
                                                    gridData={this.state.industryDimensionValuesGridData}
                                                    colData={this.state.colDef}
                                                    enablePagination
                                                    enableRowClick
                                                    onRowSelect={this._onEditRowClick}
                                                    enableDeleteColumn={true}
                                                    onRowDelete={this._handleDeleteConfirmation}
                                                />
                                            </div>
                                        </Col> */}
                                        {/* {this.state.isModalOpen && (
                                            <TatvamModal
                                                open={this.state.isModalOpen}
                                                header={this.state.modalTitle}
                                                className="hideFooter"
                                                options={{dismissible: false}}
                                                fixedFooter={false}
                                                children={(
                                                    <IndustryDimensionValueForm
                                                        applicationMode={this.state.applicationMode}
                                                        closeModal={this.closeModal}
                                                        industryDimensionValueData={this.state.dimensionValueData}
                                                        industryDimensionValueList={this.state.industryDimensionValuesGridData}
                                                        nonEditable={this.state.nonEditable}
                                                        industryName={this.state.industryName}
                                                    />
                                                )}
                                            />
                                        )} */}
                                    {/* </TabPanel> */}

                                        {/* Stages */}
                                        <TabPanel>
                                            <IndustryStages
                                                industryName={this.state.industry_name}
                                                industryId={this.state.industry_id}
                                                statusCount={this._handelStageCount}
                                            />
                                        </TabPanel>

                                        {/* touchpoint */}
                                        <TabPanel>
                                        <IndustryTouchpoints
                                                industryName={this.state.industry_name}
                                                industryId={this.state.industry_id}
                                                statusCount={this._handelTouchpointCount}
                                            />
                                        </TabPanel>

                                        {/* Dimension Values */}
                                        <TabPanel>
                                        <IndustryDimensionValues
                                                industryName={this.state.industry_name}
                                                industryId={this.state.industry_id}
                                                statusCount={this._handelDimensionValuesCount}
                                            />
                                        </TabPanel>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                    {this.state.isAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._discard}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                    {this.state.isDeleteAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isDeleteAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._deleteRow}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                </Row>
            </Row>
        );
    }
}

export default IndustryDimensionValuesDetails;
