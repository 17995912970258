import React, { Fragment } from "react";
import { ALERT_MESSAGES, APPLICATION_MODES, DIMENSION_INDUSTRY_VALUES } from "../../../../../constants/appConstants";
import { TatvamButton, TatvamGrid, TatvamModal, TatvamCol } from "../../../../functionalComponents";
import { trimCapitalize } from "../../../../../utils/stringUtil";
import IndustryDimensionValuesImport from "../../../industryDimensionsImport";
import { withRouter } from "react-router-dom";
import AlertBox from "../../../alertBox";

class IndustryDimensionValues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colDef: [
                {
                    headerName: DIMENSION_INDUSTRY_VALUES.SEQ_NO,
                    field: "seq_no",
                    filterParams: {clearButton: true},
                    width: 225
                },
                {
                headerName: DIMENSION_INDUSTRY_VALUES.STAGE_NAME,
                field: "stages",
                filterParams: {clearButton: true},
                width: 225
            },
            {
                headerName: DIMENSION_INDUSTRY_VALUES.TOUCHPOINT_NAME,
                field: "touchpoints",
                filterParams: {clearButton: true},
                width: 225
            },
            {
                headerName: DIMENSION_INDUSTRY_VALUES.PARENT_ENTITY_TYPE,
                field: "parent_entity_type",
                filterParams: {clearButton: true},
                width: 225
            },
            {
                headerName: DIMENSION_INDUSTRY_VALUES.PAREN_ENTITY_VALUE,
                field: "parent_entity_value",
                filterParams: {clearButton: true},
                width: 225
            },
            {
                headerName: DIMENSION_INDUSTRY_VALUES.ENTITY_TYPE,
                field: "entity_type",
                filterParams: {clearButton: true},
                width: 225
            },
            {
                headerName: DIMENSION_INDUSTRY_VALUES.ENTITY_VALUE,
                field: "entity_value",
                filterParams: {clearButton: true},
                width: 225
            },
                {
                    headerName: DIMENSION_INDUSTRY_VALUES.STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    width: 227
                },
            ],
            loader: true,
            showImportModal: false
        }
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            id: data.id,
            idDimension: data.id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };

    _deleteRow = (data) => {
        if (data === "ok") {
            let dimension_id = this.state.id;
            let id = this.state.id;
            let dimensionDeleteData = {
                dimension_id,
                id
            };
            this.props.actions.deleteDimensionIndustry(dimensionDeleteData).then((response) => {
                this.setState({
                    loader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    };

    _openViewModal = (data) => {
        this.setState({
           dimensionData: data[0],
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true,
            loader: true
        });
    };

    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    componentDidMount() {
        let industryDimensionValuesGridData = [];
        if (this.props.history.location.state.industryData.name) {
            this.setState({
                loader: true
            });

            this.props.actions.getIndustryDimensionsValuesByIndustryName(this.props.history.location.state.industryData.name).then(() => {

                this.setState({
                    loader: false
                });

            });
        }
        
        if (this.props.industryDimensionsValueList.dimensionIndustry.dimensionsGridValues) {
            this.props.industryDimensionsValueList.dimensionIndustry.dimensionsGridValues.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        industryDimensionValuesGridData.push(data);
                    }
                }
            });
        }

        this.setState({
            industryDimensionValuesGridData,
        }, () => {

            this.setActiveCount();
        });

    }

    setActiveCount = () => {
        const gridData = this.state.industryDimensionValuesGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.statusCount(noOfActive.length,gridData.length);
    };

    componentWillReceiveProps(props) {
        let industryDimensionValuesGridData = [];
        if (props.industryDimensionsValueList.dimensionIndustry.dimensionsGridValues) {
            props.industryDimensionsValueList.dimensionIndustry.dimensionsGridValues.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        industryDimensionValuesGridData.push(data);
                    }

                }
            });
        }
        this.setState({
            industryDimensionValuesGridData,
        }, () => {

            this.setActiveCount();
        });
    }

    _importStages = () => {
        this.setState({
            modalTitle: "",
            showImportModal: true
        });
    }

    closeImportModal = () => {
        this.setState({
            showImportModal: false
        });
    };

    render() {
        return (
            <Fragment>
                 <TatvamButton className="mt-1 ml-2 right ad-vis-btn btn_primary mr-1"
                                      onClick={this._importStages}
                                      title="Import Industry dimensions values">
                            <i className="large material-icons">  import_contacts  </i>
                        </TatvamButton>


                        {
                            this.state.industryDimensionValuesGridData && this.state.industryDimensionValuesGridData.length > 0 ? (
                                <TatvamCol s={12} className="grid-data-area">
                                    <TatvamGrid
                                        gridData={this.state.industryDimensionValuesGridData}
                                        colData={this.state.colDef}
                                        rowSelection="multiple"
                                        onRowSelect={this._openViewModal}
                                        enableDeleteColumn={true}
                                        onRowDelete={this._handleDeleteConfirmation}
                                    />
                                </TatvamCol>
                            ) : this.state.loader ? (
                                <TatvamCol s={12} className="grid-empty-area">
                                    <h5>Loading...</h5>
                                </TatvamCol>
                            ) : (
                                <TatvamCol s={12} className="grid-empty-area">
                                    <h5>No Dimensions available</h5>
                                </TatvamCol>
                            )
                        }


                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}

                        {this.state.showImportModal && (
                    <TatvamModal
                        open={this.state.showImportModal}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <IndustryDimensionValuesImport
                                closeModal={this.closeImportModal}
                                industryName={this.props.industryName}
                            />
                        )}
                    />
                )
                }
                </Fragment>
        )
    }
}

export default withRouter(IndustryDimensionValues);