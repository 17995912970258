import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getIndustryStages, importIndustryStages,} from "../../../actions";
import IndustryStageImport from "./industryStageImport";

const mapStateToProps = (state, ownProps) => ({
    industryStagesList: state.industryStages,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            importIndustryStages,
            getIndustryStages,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryStageImport);
