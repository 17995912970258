import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IndustryTouchpoints from "./industryTouchpoints";
import { getIndustryTouchpoints, importIndustryTouchpoints } from "../../../../../actions";
import { deleteIndustryTouchpoint } from "../../../../../actions/industryTouchpoinActions";

const mapStateToProps = (state, ownProps) => ({
    industryTouchpointsList: state.industryTouchpoints,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            importIndustryTouchpoints,
            getIndustryTouchpoints,
            deleteIndustryTouchpoint
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryTouchpoints);
