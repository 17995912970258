import React, {Fragment} from 'react';

import Loader from 'react-loaders';
import {Col, Row, Switch,} from 'react-materialize';

import {ALERT_MESSAGES, APPLICATION_MODES, FORM_OPERATIONS, SOURCE_INDUSTRY,} from '../../../constants/appConstants';
import {trimCapitalize, validateCustomPattern,} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class SourceIndustryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            editFlag: {},
            isActive: false,
            status: "Inactive",
            name: "",
            navigateToDetails: false,
            navigationdata: []

        };
    }

    _handleInput = (e) => {
        if (e.target.id === "status") {
            this.setState({
                isActive: e.target.checked,
                [e.target.id]: e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE,
                editFlag: {
                    [e.target.id]: true
                }
            });
        } else if (e.target.name === "name") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^[a-zA-Z0-9][a-zA-Z0-9_ ]{0,255}$/g)) {
                return false;
            }

        }
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                [e.target.name]: true
            }
        });
    };

    _discard = (data) => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.props.closeModal();
                if (this.state.navigateToDetails === true && this.state.navigationdata.length > 0) {
                    this.props.history.push({
                        pathname: "/industryDimensions",
                        state: {
                            industryData: this.state.navigationdata[0],
                            returnPath: "/industry"
                        }
                    });
                }
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _triggerDiscard = () => {
        if (Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });

        } else {
            this.props.closeModal();
        }
    };

    _triggerSubmit = () => {
        this.refs.submit.click();

    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    _handleDeleteConfirmation = () => {
        this.setState({
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    }

    _deleteAction = (data) => {

        if (data === "ok") {
            this._handleDelete();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    };

    _handleSave = e => {
        e.preventDefault();
        let {name, id, status} = this.state;
        let industryData = {
            name,
            id,
            status
        };
        if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            industryData.created_by = this.props.userName;
            industryData.id = (parseInt(this.props.maxId) + 1).toString();
            this.setState({
                preLoader: true
            });
            this.props.actions.createIndustry(industryData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message,
                        navigateToDetails: true,
                        navigationdata: response.data.data
                    });

                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message,
                        navigateToDetails: false
                    });

                }
            });
        }
        if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
            industryData = {
                ...this.props.formData
            };
            industryData.name = name;
            industryData.id = id;
            industryData.status = status;
            industryData.updated_by = this.props.userName;
            this.setState({
                preLoader: true
            });
            this.props.actions.updateIndustry(industryData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }

            });

        }
    };

    _handleDelete = () => {

        let {industry_id} = this.state;
        let industryData = {
            industry_id

        };
        this.props.actions.deleteIndustry(industryData).then((response) => {
            this.setState({
                preLoader: false
            });
            if (response.status === 200) {
                this.setState({
                    shouldCloseModal: true,
                    isAlertBoxOpen: true,
                    isSuccess: true,
                    alert_information: response.data.message
                });
            } else {
                this.setState({
                    shouldCloseModal: false,
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.data ? response.data.message : response.message
                });

            }

        });

    }

    componentDidMount() {
        let isActive;
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({inValidForm: true});
                }, true);
        }

        if (this.props.formData) {
            this.setState({
                ...this.props.formData,
                isActive: trimCapitalize(this.props.formData.status) === APPLICATION_MODES.ACTIVE
            });
            if (this.props.formData.status)
                isActive = trimCapitalize(this.props.formData.status) === APPLICATION_MODES.ACTIVE;
        }
        this.setState({
            isActive
        });
    }

    componentWillReceiveProps(props) {
        let isActive;
        if (props.formData) {
            this.setState({
                ...props.formData,
                isActive: trimCapitalize(props.formData.status) === APPLICATION_MODES.ACTIVE
            });
            if (props.formData.status)
                isActive = trimCapitalize(props.formData.status) === APPLICATION_MODES.ACTIVE;
        }
        this.setState({
            isActive
        });
    }

    render() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({inValidForm: true});
                }, true);
        }
        return (
            <Fragment>
                <Row
                    className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait header-margin"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{SOURCE_INDUSTRY.VIEW_INDUSTRY}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4 className="mb-4">{SOURCE_INDUSTRY.EDIT_INDUSTRY}</h4>) : ""}</Row>
                <Row>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <form onSubmit={(e) => this._handleSave(e)}>
                            <TatvamTextBox
                                className={(this.state.inValidForm && !this.state.name) ? "labelText mt-0 validate project_name mb-4 invalid" : "labelText mt-0 validate project_name mb-4"}
                                s={6}
                                m={6}
                                l={6}
                                xl={6}
                                label={
                                    <span>
                    {SOURCE_INDUSTRY.NAME}
                                        <span style={{color: 'red'}}> *</span>
                  </span>
                                }
                                required
                                aria-required={true}
                                name="name"
                                onChange={this._handleInput}
                                value={this.state.name}
                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                autocomplete="off"
                                maxlength={255}
                            />
                            <Col m={12} l={6} className="switch-wrapper pb-3">
                                <Switch
                                    id="status"
                                    m={6}
                                    offLabel="Status"
                                    checked={this.state.isActive}
                                    onClick={this._handleInput}
                                />
                            </Col>
                            <button type="submit" ref="submit" className="hide">
                                Submit
                            </button>
                        </form>
                    ) : (
                        <Col m={12}>
                            <p className="col m12 l6">
                                <span className="property-label col s3"> {SOURCE_INDUSTRY.VIEW_NAME} : </span>
                                <span className="col s9">{this.state.name} </span>
                            </p>
                            <p className="col m12 l6">
                                <span className="property-label col s4">{SOURCE_INDUSTRY.STATUS} : </span>
                                <span className="col s8">{this.state.status} </span>
                            </p>
                        </Col>
                    )}
                    <Col s={12}>
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="CreateProjectIndustry btn_primary "
                                    onClick={this._triggerSubmit}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                            (
                                <Fragment>
                                    <TatvamButton waves="light" className=" CreateProjectIndustry btn_primary "
                                                  name="EDIT" onClick={this._handleAppMode}>
                                        {FORM_OPERATIONS.EDIT}
                                    </TatvamButton>
                                </Fragment>
                            )}
                        <TatvamButton waves="light" className=" mr-1 CreateProjectIndustry btn_primary"
                                      onClick={this._triggerDiscard}>
                            {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                        </TatvamButton>
                    </Col>
                    {this.state.isAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._discard}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                    {this.state.isDeleteAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isDeleteAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._deleteAction}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                </Row>
            </Fragment>

        );
    }
}

export default SourceIndustryForm;
