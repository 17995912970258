import config from '../../config';



export const apiUrl = {
    BASE_URL: config.server.base_url || "http://localhost:3000/3.0/",
    READ_CUSTOMER_CATEGORY: "/category/",

    READ_ALL_CUSTOMER_BY_USER: "users/{0}/clm/customersList/false",
    READ_ALL_CUSTOMER: "customer",
    CREATE_CUSTOMER: "customer",
    UPDATE_CUSTOMER: "customer/{0}",
    DELETE_CUSTOMER: "customer/{0}",
    GET_ONE_CUSTOMER: "customer/{0}",
    READ_ALL_CUSTOMER_LIMIT_FIELDS: "customer/limitedFields/all",

    GET_CUSTOMER_CATEGORY: "customerCategory",
    GET_CUSTOMER_USERS: "customer/{0}/users",
    CREATE_CUSTOMER_USERS: "customer/{0}/users",
    UPDATE_CUSTOMER_USERS: "customer/{0}/users/{1}",
    DELETE_CUSTOMER_USERS: "customer/{0}/userRole/{1}",
    GET_ALL_USER_CUSTOMERS: "customer/allusercustomers",

    GET_CUSTOMER_ROLES: "customer/{0}/roles",
    CREATE_CUSTOMER_ROLES: "customer/{0}/roles",
    UPDATE_CUSTOMER_ROLES: "customer/{0}/roles/{1}",
    DELETE_CUSTOMER_ROLES: "customer/{0}/roles/{1}",

    GET_OPERATIONS: "operations",

    GENERATE_API_KEY: "customer-api/generate_api_token",

    GET_REPORTS: "reports/allParents/report",

    GET_ALL_USERS: "users/customer/count",
    GET_USER_DETAILS: "users/{0}",
    CREATE_USER: "users",
    UPDATE_USER: "users/{0}",
    AUTH_USER: "auth",
    GET_USER_PERMISSIONS: "users/{0}/permissions",
    GET_CUSTOMER_RULES: "customer/{0}/rules",
    CREATE_CUSTOMER_RULES: "customer/{0}/rules",
    UPDATE_CUSTOMER_RULES: "customer/{0}/rules/{1}",
    DELETE_CUSTOMER_RULES: "customer/{0}/rules/{1}",
    GET_CUSTOMER_COMPETITORS: "customer/{0}/competitors/true",
    DELETE_CUSTOMER_COMPETITORS: "customer/{0}/competitors/{1}",
    GET_COMPETITORS_CUSTOMERS: "customer/{0}/competitors",
    EDIT_COMPETITORS_CUSTOMERS: "customer/{0}/competitors/{1}",

    GET_CUSTOMER_TENANTS: "customer/{0}/tenant",
    DELETE_CUSTOMER_TENANTS: "customer/{0}/tenant/{1}",
    DELETE_CUSTOMER_PROPERTY: "customer/{0}/property/{1}",
    GET_CUSTOMER_INDUSTRY: "customer/industry/{0}/category/{1}",

    GET_CUSTOMER_TENANT_INDUSTRY: "customer/category/{0}/true",
    GET_CUSTOMER_PARENT: "customer/{0}/parents",

    USER_DETAILS: "users/",


    GET_ONE_CUSTOMER_SOURCES: "customer/{0}/sources",
    CREATE_CUSTOMER_SOURCES: "customer/{0}/sources",
    UPDATE_CUSTOMER_SOURCES: "customer/{0}/sources/{1}",
    DELETE_CUSTOMER_ONE_SOURCE: "customer/{0}/sources/{1}",
    DELETE_CUSTOMER_ALL_SOURCE: "customer/{0}/sources",

    GET_USER_CUSTOMERS: "users/{0}/customers",
    DELETE_USER_CUSTOMER: "customer/{0}/userRole/{1}",


    GET_ALL_CUSTOMER_SOURCES: "source/allSources/details",
    GET_ALL_CUSTOMER_SOURCES_BY_TYPE: "source/type/{0}",
    GET_SOURCE_ALL_XPATHS: "source/{0}/xpaths",
    GET_SOURCE_ONE_XPATH: "source/{0}/xpaths/{1}",
    CREATE_SOURCE_XPATH: "source/{0}/xpaths/",
    UPDATE_SOURCE_XPATH: "source/{0}/xpaths/{1}",
    DELETE_SOURCE_ALL_XPATHS: "source/{0}/xpaths",
    DELETE_SOURCE_ONE_XPATH: "source/{0}/xpaths/{1}",
    GET_SOURCE_CATEGORY: "category",
    GET_API_SOURCE_DETAILS: "apiSourceDetails/{0}",
    CREATE_SOURCE_API_DETAILS: "source/{0}/apiSourceDetail",
    GET_SOURCE_ALL_INDUSTRY: "industry",
    UPDATE_SOURCE_API_DETAILS: "source/{0}/apiSourceDetail/{1}",
    CREATE_SOURCE_INDUSTRY: "industry",
    UPDATE_SOURCE_INDUSTRY: "industry/{0}",
    DELETE_SOURCE_INDUSTRY: "industry/{0}",
    GET_COMMENT_TYPE: "commentType",
    GET_CUSTOMER_DIMENSIONS: "customerDimension/{0}/dimensions",
    GET_PREDICTO_SOURCE_DETAILS: "predictoSource",
    GET_CUSTOMER_PREDICTO_SOURCE_DETAILS: "customer/{0}/predictoSources",
    CREATE_PREDICTO_SOURCE_INDUSTRY: "customer/{0}/predictoSource",
    UPDATE_PREDICTO_SOURCE_INDUSTRY: "customer/{0}/predictoSource/{1}",
    DELETE_PREDICTO_SOURCE_INDUSTRY: "customer/{0}/predictoSource/{1}",
    CREATE_CUSTOMER_DIMENSIONS: "customerDimension",
    DELETE_CUSTOMER_DIMENSIONS: "customerDimension/{0}/dimension/{1}",
    UPDATE_CUSTOMER_DIMENSIONS: "customerDimension/{0}",
    GET_CUSTOMER_DIMENSION_VALUES: "customerDimensionValue/{0}/dimensionValues",
    CREATE_CUSTOMER_DIMENSION_VALUE: "customerDimensionValue",
    UPDATE_CUSTOMER_DIMENSION_VALUE: "customerDimensionValue/{0}",
    DELETE_CUSTOMER_DIMENSION_VALUE: "customerDimensionValue/{0}",
    GET_INDUSTRY_DIMENSION_VALUES: "industryDimensionValue/",
    CREATE_INDUSTRY_DIMENSION_VALUE: "industryDimensionValue/",
    UPDATE_INDUSTRY_DIMENSION_VALUE: "industryDimensionValue/{0}",
    DELETE_INDUSTRY_DIMENSION_VALUE: "industryDimensionValue/{0}",
    GET_ALL_XPATHS: "source/xpath/all",
    GET_ALL_CUSTOMER: "customer/all",
    GET_COMPETITOR_CUSTOMERS: "customer/{0}/competitorCustomers",
    GET_TENANT_CUSTOMERS: "customer/{0}/tenantCustomers",
    DELETE_USER: "users/{0}",
    GET_DIMENSION_VALUES_BY_INDUSTRY: "industryDimensionValue/{0}/dimensionValues",
    GET_ALL_TIMEZONES: "timeZones",
    GET_CUSTOMER_RESPONSE_TEMPLATE: "customer/{0}/response-templates",
    DELETE_RESPONSE_TEMPLATE: "customer/{0}/response-template/{1}",
    CREATE_CUSTOMER_RESPONSE_TEMPLATE: "customer/{0}/response-template",
    UPDATE_CUSTOMER_RESPONSE_TEMPLATE: "customer/{0}/response-template/{1}",
    BEARER_TEXT: "Bearer ",
    REFRESH_TOKEN_ENDPOINT: "auth/token",
    CUSTOMER_TAB_COUNT: "customer/allTabsCount/{0}",
    GET_REPORT_SUBSCRIPTION: "customer/{0}/subscriptions",
    GET_TAG_TREATMENT: "tagTreatment/all/active",
    GET_USER_TYPE: "userType",
    SEND_MAIL: "users/sendMail",
    GET_CUSTOMER_USER_DETAILS: "customer/{0}/userDetails",
    GET_SUBSCRIPTION_REPORTS: "reports/subscriptionReports/all/{0}",
    GET_CHILD_PROPERTY: "customer/{0}/children",
    CREATE_CUSTOMER_REPORT_SUBSCRIPTIONS: "customer/{0}/subscription",
    UPDATE_CUSTOMER_REPORT_SUBSCRIPTIONS: "customer/{0}/subscription/{1}",
    UPLOAD_FILE: "files/upload/{0}",
    GET_DIRECTORY_LISTING: "files/directories",
    IMPORT_USERS: "fileUpload/users",
    GET_ALL_STATES: "states",
    GET_CUSTOMER_REPONSES: "customer/{0}/responseStatus",
    GET_ALL_APPLICATION_NAMES: "application/",
    IMPORT_CUSTOMER_DIMENSION_VALUES: "customerDimensionValue/upload/dimensionValues",

    GET_INFLUENCERS_BY_CUSTOMER_ID: "customer/{0}/influencers",
    CREATE_CUSTOMER_INFLUENCERS: "customer/{0}/influencer",
    EDIT_CUSTOMER_INFLUENCER: "customer/{0}/influencer/{1}",
    DELETE_CUSTOMER_INFLUENCER: "customer/{0}/influencer/{1}",
    GET_ALL_USERS_BY_FIELD: "users/field/username",

    CUSTOMER_USAGE_LOG: "customers/usagelog",
    CUSTOMER_USER_USAGE_LOG: "customers/users/usagelog",
    CUSTOMER_REPORTS_USAGE_LOG: "customers/reports/usagelog",
    CUSTOMER_USER_REPORTS_USAGE_LOG: "customers/users/reports/usagelog",
    REPORTS_USAGE_LOG: "reports/usagelog",

    TENANT_INDUSTRY_CLASSIFICATION: "customer/{0}/industry_classifications",
    TENANT_CREATE: "customer/tenant",
    TENANT_EDIT: "customer/tenant/from_clm/{0}",
    TENANT_DELETE: "customer/tenant/{0}",


    GET_ALL_INDUSTRY_STAGES: "industry-stages",
    GET_INSERT_INDUSTRY_STAGES: "industry/{0}/industry-stages",
    IMPORT_INDUSTRY_STAGES: "industry/{0}/upload/industry-stages",
    UPDATE_DELETE_INDUSTRY_STAGE: "industry-stages/{0}",


    GET_INDUSTRY_TOUCHPOINTS: "industry/{0}/industry-stage-touchpoints",
    CREATE_INDUSTRY_TOUCHPOINT: "industry/{0}/industry-stage-touchpoints",
    UPDATE_INDUSTRY_TOUCHPOINTS: "industry-stage-touchpoints/{0}",
    DELETE_INDUSTRY_TOUCHPOINT: "/industry-stage-touchpoints/{0}",
    IMPORT_INDUSTRY_TOUCHPOINT: "industry/{0}/upload/industry-stage-touchpoints",

    IMPORT_INDUSTRY_DIMENSION: "industryDimensionValue/industry/{0}/upload",
    GET_INDUSTRY_DIMENSION: "industryDimensionValue/industry/{0}",
    DELETE_INDUSTRY_DIMENSION:"industryDimensionValue/{0}"
};

export const alertMessages = {
    LOGOUT_CONFIRMATION_MESSAGE: "Are you sure. You want to log out?"
};





