import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IndustryStageForm from "./industryStageForm";

const mapStateToProps = (state, ownProps) => (
    {
       
    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
          
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(IndustryStageForm);
