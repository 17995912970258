import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getIndustryDimensionsValuesByIndustryName, importIndustryDimensionsValues,} from "../../../actions";
import IndustryDimensionValuesImport from "./industryDimensionsImport";

const mapStateToProps = (state, ownProps) => ({
    industryDimensionsValueList: state.dimensionIndustry,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            importIndustryDimensionsValues,
            getIndustryDimensionsValuesByIndustryName,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryDimensionValuesImport);
