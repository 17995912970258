import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getIndustryTouchpoints, importIndustryTouchpoints,} from "../../../actions";
import IndustryTouchpointsImport from "./industryTouchpointsImport";

const mapStateToProps = (state, ownProps) => ({
    industryTouchpointsList: state.industryTouchpoints,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            importIndustryTouchpoints,
            getIndustryTouchpoints,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryTouchpointsImport);
