import React, { Fragment } from "react";
import { ALERT_MESSAGES, APP_BOOLEANS, APPLICATION_MODES, FORM_OPERATIONS, STAGES_GRID, SWITCH_TEXT } from "../../../constants/appConstants";
import { TatvamButton, TatvamCol } from "../../functionalComponents";
import AlertBox from "../alertBox";
import { Row } from "react-materialize";
import Loader from "react-loaders";

class IndustryStageForm extends React.Component {
    constructor(props) {
        super(props);
        console.log('props', this.props)
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            preLoader: false,
            status: false,
            isValid: true,
            editFlag: false,
        }
    }

    _handleSave = e => {
        e.preventDefault();
        
    }

    componentDidMount() {
      
        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (this.props.stageData) {
                this.setState({
                    ...this.props.stageData,
                    stage_name: this.props.stageData.stages,
                    status: this.props.stageData.status === APPLICATION_MODES.ACTIVE ? true : false
                });

            }
        }


    }

    _validations = () => {
        let mandatoryFieldsValid;
        if (!this.state.stage_name ) {
            mandatoryFieldsValid = false;
        } else {
            mandatoryFieldsValid = true;
        }
        this.setState({
            isValid: mandatoryFieldsValid

        });
        return Promise.resolve(true);
    }

    _handleInput = e => {
        if (e.target.id === "status") {

            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    //reset the state
                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{STAGES_GRID.VIEW_TITLE_INDUSTRY_STAGE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{STAGES_GRID.EDIT_TITLE_INDUSTRY_STAGE}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12} className="align-flex-end">

                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="stage_name">{STAGES_GRID.STAGE_NAME} <span
                                    style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.stage_name) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                    s={12}
                                    required
                                    name="stage_name"
                                    onChange={this._handleInput}
                                    value={this.state.stage_name}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                           
                        </TatvamCol>
                       
                        <TatvamCol m={12}>

                            <TatvamCol s={6} className="switch-wrapper">
                                <Switch
                                    id="status"
                                    offLabel="Status"
                                    checked={this.state.status}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>

                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {STAGES_GRID.STAGE_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.stage_name}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span className="property-label col s4"> {STAGES_GRID.STATUS} :</span>
                                <span
                                    className="col s8">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>

                     
                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                    <TatvamCol s={12} m={12} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            }
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default IndustryStageForm;