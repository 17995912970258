import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    getIndustryDimensionsValuesByIndustryName
} from "../../../../../actions";
import IndustryDimensionValues from "./industryDimensionValues";
import { deleteDimensionIndustry } from "../../../../../actions/dimensionsIndustryActions";

const mapStateToProps = (state) => ({
    industryDimensionsValueList: state.dimensionIndustry,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryDimensionsValuesByIndustryName,
            deleteDimensionIndustry
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryDimensionValues);

