import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    getIndustryStages,
    deleteIndustryStage
} from "../../../../../actions";
import IndustryStages from "./industryStages";

const mapStateToProps = (state) => ({
    industryStagesList: state.industryStages,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryStages,
            deleteIndustryStage
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryStages);

