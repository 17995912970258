import 'react-datepicker/dist/react-datepicker.css';
import './customerForm.scss';

import React, { Component, Fragment, } from 'react';

import Loader from 'react-loaders';
import { Col, Row, Switch, } from 'react-materialize';
import { trimCapitalize, validateCustomPattern, validateEmail } from 'stringUtil';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APP_STATUS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    PROPERTY,
    PROPERTY_ROLES,
    TEXT_VALIDATION,
} from '../../../constants/appConstants';
import { dynamicSort } from '../../../utils/objectUtil';
import {
    TatvamButton,
    TatvamCol,
    TatvamDatePicker,
    TatvamModal,
    TatvamMultiSelect,
    TatvamTextArea,
    TatvamTextBox,
} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import CropImageForm from './cropImageForm';
import { isArray } from 'lodash';

class CustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationMode: props.applicationMode,
            currentCustomer: props.currentCustomer,
            industryListOptions: [],
            industryClassificationListOptions: [],
            propertyListOption: [],
            categoryListOptions: [],
            categoryListOptionsLimited: [],
            licenseTypeListOptions: [],
            industryList: [],
            categoryList: [],
            selectedCategory: [],
            selectedLicense: [],
            selectedIndustry: [],
            selectedIndustryClassification: [],
            selectedParentProperty: [],
            selectedAdditionalIndustry: [],
            addIndustryListOptions: [],
            preLoader: false,
            logo: "",
            id: "",
            industry: "",
            industry_classification: "",
            parent_id: "",
            category: "",
            license_type: "",
            status: APPLICATION_MODES.INACTIVE,
            parent_customer: [],
            isActive: false,
            execution_batch: "",
            isValidDate: true,
            mandatoryFields: true,
            fromCategorypage: "",
            categoryIsDisabled: false,
            timeZoneListOptions: [],
            selectedTimeZone: [],
            timeZoneValueChanged: false,
            isModalClose: true,
            imageCropModal: false,
            subscription_batch: "",
            is_translation: false,
            is_accuracy: false,
            statusListOptions: [{ label: "Active", value: "Active" }, {
                label: "Inactive",
                value: "Inactive"
            }, { label: "Setup Pending", value: "Setup Pending" }],
            selectedStatus: [],
            website: "",
            selectedCountry: [],
            countryListOptions: [],
            selectedState: [],
            stateListOptions: [],
            is_action_trackable: false,
            is_response_trackable: false,
            is_source_weightage: false,
            is_curse_word: false,
            is_campaignable: false,
            max_capacity: "",
            predicto_batch: "",
            predicto_percentage: "",
            is_inapp: false,
            is_predicto: false,
            is_blms: false,
            predicto_subscription_batch: "",
            inapp_subscription_batch: "",
            is_chatbot: false,
            reportsList: [],
            selectedDefaultReport: [],
            selectedPredictoDefaultReport: [],
            predictoReportList: [],
            ratingTypeListOption: [
                { label: "AI Rating", value: "ai_rating" },
                { label: "Google Rating", value: "google_rating" },
                { label: "Tatvam Rating", value: "tatvam_rating" },
            ],
            selectedRatingType: { label: "", value: "" },
            DatabaseListOption: [
                { label: "tatvam_dm_live3", value: "tatvam_dm_live3" },
                { label: "tatvam_dm_demo", value: "tatvam_dm_demo" },
                { label: "tatvam_tenants_dm", value: "tatvam_tenants_dm" },
            ],
            selectedDatabaseName: { label: "", value: "" }
        };

        this.licenseList = ["Essentials", "Lite", "Pro"];
        this.licenseList.sort();
    }

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
        this.props.currentCustomerFormState(APPLICATION_MODES.EDIT);
    };
    _goTo = (data) => {
        if (this.props.customerList[data.id]) {
            this.props.history.push({
                pathname: "/customerdetails",
                state: {
                    applicationMode: APPLICATION_MODES.VIEW,
                    id: data.id,
                    fromCategoryPage: this.props.location.state.fromCategoryPage,
                    isGroupInstance: this.props.location.state.isGroupInstance
                }
            });
        } else {
            this.setState({
                goToParent: false
            });
        }
    };
    _triggerDiscard = e => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                notDiscard: false,
                isModalClose: true
            });
        } else if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({
                name: "",
                id: "",
                industry: "",
                category: "",
                license_type: "",
                status: APPLICATION_MODES.ACTIVE,
                logo: "",
                isActive: false,
                isCorporate: false,
                editFlag: "",
                isValidDate: true,
            });
            this.props.history.goBack();
        } else {
            this.setState({
                applicationMode: APPLICATION_MODES.VIEW
            });
            this.props.currentCustomerFormState(APPLICATION_MODES.VIEW);
        }
    };
    _triggerSubmit = () => {
        this.refs.submitForm.click();
    };

    _validations = () => {
        if ((this.state.selectedCategory.label !== "Tenants" && Array.isArray(this.state.selectedIndustry)) || Array.isArray(this.state.selectedCategory) || Array.isArray(this.state.selectedStatus)) {
            this.setState({
                mandatoryFields: false
            });
        } else if ((this.state.selectedCategory.label === "Tenants" && Array.isArray(this.state.selectedIndustryClassification) && Array.isArray(this.state.selectedParentProperty)) || Array.isArray(this.state.selectedCategory) || Array.isArray(this.state.selectedStatus)) {
            this.setState({
                mandatoryFields: false
            });
        } else {
            if (this.state.selectedCategory.label !== "Competitor" && this.state.selectedCategory.label !== "Tenants") {
                if (Array.isArray(this.state.selectedLicense)) {
                    this.setState({
                        mandatoryFields: false
                    });
                } else {
                    let isValidDate = new Date(this.state.valid_from) < new Date(this.state.valid_upto);
                    this.setState({
                        isValidDate: isValidDate,
                        mandatoryFields: true
                    });
                }

            } else {
                this.setState({
                    mandatoryFields: true,
                    isValidDate: true
                });
            }

        }
        return Promise.resolve(true);
    };

    _handleSave = e => {
        e.preventDefault();
        if (!this.state.name ||
            this.state.name === "" || this.state.selectedCategory.length === 0 || this.state.selectedStatus.length === 0 ||
            (this.state.is_campaignable) ||
            (this.state.is_inapp && this.state.selectedDefaultReport.length === 0 && this.state.selectedRatingType.length === 0) ||
            this.state.selectedDatabaseName.length === 0 || (this.state.is_predicto && this.state.selectedPredictoDefaultReport.length === 0)) {
            this.setState({ inValidForm: true });
            return false;
        } else if ((this.state.selectedCategory.value !== "Tenants") && (this.state.selectedIndustry.length === 0 || this.state.selectedTimeZone.length === 0 || this.state.selectedCountry.length === 0 || this.state.selectedState.length === 0 || !this.state.address || this.state.address === "" || !this.state.id || this.state.id === "")) {
            this.setState({ inValidForm: true });
            return false;
        } else if ((this.state.selectedCategory.value === "Tenants") && (this.state.selectedIndustryClassification.length === 0 || this.state.selectedParentProperty.length === 0)) {
            this.setState({ inValidForm: true });
            return false;
        } else if ((this.state.selectedCategory.value === "Live" || this.state.selectedCategory.value === "Demo") && (this.state.selectedLicense.length === 0 || this.state.selectedLicense.value === "" || !this.state.valid_from || !this.state.valid_upto)) {
            this.setState({ inValidForm: true });
            return false;
        } else {
            this._validations().then((responseValidation) => {
                if (responseValidation) {
                    if (this.state.isValidDate && this.state.mandatoryFields) {
                        let customerForm = {};
                        let addtnlIndustry = [];
                        if (Array.isArray(this.state.selectedAdditionalIndustry)) {
                            this.state.selectedAdditionalIndustry.map(data => {
                                addtnlIndustry = [...addtnlIndustry, data.value];
                            });
                        }
                        const timeZoneData = Object.values(this.props.timeZoneList).filter((item) => {
                            return item.time_zone === this.state.selectedTimeZone.label;
                        });

                        let subscriptionData = [];
                        if (this.state.inapp_subscription_batch) {
                            subscriptionData.push({
                                "application_name": APPLICATION_MODES.TATVAM_INAPP,
                                "batch_name": this.state.inapp_subscription_batch
                            });
                        }
                        if (this.state.predicto_subscription_batch) {
                            subscriptionData.push({
                                "application_name": APPLICATION_MODES.PREDICTO,
                                "batch_name": this.state.predicto_subscription_batch
                            });
                        }
                        customerForm = {
                            id: this.state.id,
                            name: this.state.name,
                            category: this.state.selectedCategory.value,
                            industry: this.state.selectedIndustry.label,
                            industry_classification: this.state.selectedIndustryClassification.label,
                            additionalIndustry: addtnlIndustry,
                            license_type: this.state.selectedLicense.value,
                            corporate:
                                this.state.isCorporate
                                    ? APPLICATION_MODES.YES
                                    : APPLICATION_MODES.NO,
                            logo: this.state.logo,
                            status: this.state.selectedStatus.label,
                            valid_from: this.state.valid_from,
                            valid_upto: this.state.valid_upto,
                            execution_batch: this.state.execution_batch,
                            subscription_batch: subscriptionData,
                            time_zone: this.state.selectedTimeZone.label,
                            country_code: timeZoneData && timeZoneData.length > 0 ? timeZoneData[0].country_code : "",
                            address: this.state.address,
                            country: this.state.selectedCountry.label,
                            state: this.state.selectedState.label,
                            inapp_default_landing_page: this.state.selectedDefaultReport.label,
                            inapp_default_landing_page_id: this.state.selectedDefaultReport.value,
                            predicto_default_landing_page: this.state.selectedPredictoDefaultReport.label,
                            predicto_default_landing_page_id: this.state.selectedPredictoDefaultReport.value,
                            rating_type: this.state.selectedRatingType.value,
                            website: this.state.website,
                            is_action_trackable: this.state.is_action_trackable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                            is_curse_word: this.state.is_curse_word ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                            is_campaignable: this.state.is_campaignable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                            is_response_trackable: this.state.is_response_trackable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                            is_source_weightage: this.state.is_source_weightage ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                            max_capacity: this.state.max_capacity ? parseInt(this.state.max_capacity) : 0,
                            predicto_batch: this.state.predicto_batch,
                            predicto_percentage: this.state.predicto_percentage,
                            is_predicto: this.state.is_predicto,
                            is_inapp: this.state.is_inapp,
                            is_blms: this.state.is_blms,
                            is_chatbot: this.state.is_chatbot ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                          //  customer_support_email_id: this.state.customer_support_email_id,
                            database_name: this.state.selectedDatabaseName.value,
                        };

                        if (customerForm.corporate === APPLICATION_MODES.NO) {
                            customerForm.is_translation = this.state.is_translation ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
                            customerForm.is_accuracy = this.state.is_accuracy ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
                        } else {
                            if (customerForm.corporate === APPLICATION_MODES.YES) {
                                customerForm.is_translation = APPLICATION_MODES.NO;
                                customerForm.is_accuracy = APPLICATION_MODES.NO;
                            }
                        }

                        //based on category call the api
                        if (this.state.applicationMode === APPLICATION_MODES.CREATE && this.state.selectedCategory.value === "Tenants") {
                            this._handelTenantCreation(customerForm);
                        } else if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                            customerForm.created_by = this.props.userName;

                            this.setState({
                                preLoader: true
                            });
                            this.props.actions.createCustomer(customerForm).then(response => {
                                this.setState({
                                    preLoader: false,
                                    isValid: true
                                });
                                if (response && response.status === 200) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.data.message
                                    });

                                    this.setState({
                                        applicationMode: APPLICATION_MODES.VIEW
                                    }, () => {
                                        this.props.setId(customerForm);
                                        this.props.editFlag(false);
                                    });
                                    this._goTo(customerForm);
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: false,
                                        alert_information: response.data ? response.data.message : response.message,
                                        notDiscard: true
                                    });
                                }
                            });
                        } else if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                            if (this.props.customer) {
                                if (this.props.customer.category === "Competitor" || this.props.customer.category === "Tenants") {
                                    if (this.state.selectedCategory.value === "Live" || this.state.selectedCategory.value === "Demo") {
                                        customerForm.create_default_roles = true;

                                    } else {
                                        customerForm.create_default_roles = false;
                                    }
                                } else {
                                    customerForm.create_default_roles = false;
                                }
                            }
                            if (!this.state.isModalClose) {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: true,
                                    alert_information: PROPERTY.TIME_ZONE_CHANGE_ALERT_MSG,
                                    isConfirmBox: false,
                                    isModalClose: false
                                });
                                return false;
                            }
                            customerForm.updated_by = this.props.userName;
                            this.setState({
                                preLoader: true
                            });

                            if (this.state.selectedCategory.value !== "Tenants") {
                                this.props.actions.saveCustomer(customerForm).then(response => {
                                    this.setState({
                                        preLoader: false
                                    });
                                    if (response && response.status === 200) {
                                        if (response.data && response.data.message) {
                                            this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: response.data.message
                                            });
                                        } else {
                                            this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: response.message
                                            });
                                        }
                                        this.setState({
                                            applicationMode: APPLICATION_MODES.VIEW,
                                            selectedAdditionalIndustry: []
                                        }, () => {
                                            this.props.setId(customerForm);
                                            this.props.editFlag(false);
                                        });
                                    } else {
                                        this.setState({
                                            isAlertBoxOpen: true,
                                            isSuccess: false,
                                            alert_information: response.message
                                        });
                                    }
                                });
                            } else {
                                this._handelEditTenant(customerForm);
                            }
                        }
                    }
                }
            });
        }

    };

    _handelTenantCreation = (customerForm) => {
        customerForm.created_by = this.props.userName;
        customerForm.parent_id = this.state.selectedParentProperty.value

        this.setState({
            preLoader: true
        });
        this.props.actions.createTenant(customerForm).then(response => {
            this.setState({
                preLoader: false,
                isValid: true
            });
            if (response && response.status === 200) {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: true,
                    alert_information: response.data.message
                });

                this.setState({
                    applicationMode: APPLICATION_MODES.VIEW
                }, () => {
                    const updatedCustomerData = {
                        ...customerForm,
                        id: response.data.data[0].id,
                        parent_id: this.state.selectedParentProperty.label
                    };
                    this.props.setId(updatedCustomerData);
                    this.props.editFlag(false);
                });
                this._goTo(customerForm);
            } else {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.data ? response.data.message : response.message,
                    notDiscard: true
                });
            }
        });
    };


    _handelEditTenant = (customerForm) => {
        customerForm.parent_id = this.state.selectedParentProperty.value;

        this.props.actions.saveTenant(customerForm).then(response => {
            this.setState({
                preLoader: false
            });
            if (response && response.status === 200) {
                if (response.data && response.data.message) {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.message
                    });
                }
                this.setState({
                    applicationMode: APPLICATION_MODES.VIEW,
                    // selectedAdditionalIndustry: []
                }, () => {
                    const updatedCustomerData = {
                        ...customerForm,
                        id: response.data.data[0].id,
                        parent_id: this.state.selectedParentProperty.label
                    };
                    this.props.setId(updatedCustomerData);
                    this.props.editFlag(false);
                });
            } else {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.message
                });
            }
        });
    };

    _discard = data => {

        if (data === "ok" && this.state.isModalClose === true) {
            // if (this.state.isSuccess) {
            //     return this.setState({
            //         isConfirmBox: false,
            //         isAlertBoxOpen: false,
            //         isSuccess: false
            //     });
            // }
            this.props.editFlag(false);
            if (
                this.state.applicationMode === APPLICATION_MODES.EDIT ||
                this.state.applicationMode === APPLICATION_MODES.VIEW
            ) {
                let timeZoneId = "";
                if (this.props.timeZoneList && Object.values(this.props.timeZoneList).length > 0) {
                    var currentTimeZone = Object.values(this.props.timeZoneList).filter(item => item.time_zone === this.state.currentCustomer.time_zone);
                    if (currentTimeZone.length > 0) {
                        timeZoneId = currentTimeZone[0].id;
                    }
                }


                this.setState({
                    ...this.state,
                    ...this.state.currentCustomer,
                    selectedCategory: {
                        label: this.state.currentCustomer.category,
                        value: this.state.currentCustomer.category
                    },
                    selectedIndustry: {
                        label: this.state.currentCustomer.industry,
                        value: this.state.currentCustomer.industry
                    },
                    selectedIndustryClassification: {
                        label: this.state.currentCustomer.industry_classification,
                        value: this.state.currentCustomer.industry_classification
                    },
                    selectedParentProperty: {
                        label: this.state.selectedParentProperty.label ? this.state.selectedParentProperty.label : (this.state.currentCustomer.parent_id + " - " + this.state.currentCustomer.parent_name).trim(),
                        value: this.state.selectedParentProperty.value ? this.state.selectedParentProperty.value : this.state.currentCustomer.parent_id
                    },
                    selectedAdditionalIndustry: this.state.copiedAdditionalIndustry,
                    selectedLicense: {
                        label: this.state.currentCustomer.license_type,
                        value: this.state.currentCustomer.license_type
                    },
                    selectedStatus: {
                        label: this.state.currentCustomer.status,
                        value: this.state.currentCustomer.status
                    },
                    selectedCountry: {
                        label: this.state.currentCustomer.country,
                        value: this.state.currentCustomer.country
                    },
                    selectedState: { label: this.state.currentCustomer.state, value: this.state.currentCustomer.state },
                    selectedDefaultReport: {
                        label: this.state.currentCustomer.inapp_default_landing_page,
                        value: this.state.currentCustomer.inapp_default_landing_page_id
                    },
                    selectedPredictoDefaultReport: {
                        label: this.state.currentCustomer.predicto_default_landing_page,
                        value: this.state.currentCustomer.predicto_default_landing_page_id
                    },
                    selectedRatingType: this.state.ratingTypeListOption.find(option =>
                        option.value === this.state.currentCustomer.rating_type ||
                        option.label === this.state.currentCustomer.rating_type
                    ),
                    selectedDatabaseName: this.state.DatabaseListOption.find(option =>
                        option.value === this.state.currentCustomer.database_name ||
                        option.label === this.state.currentCustomer.database_name
                    ),
                    applicationMode: APPLICATION_MODES.VIEW,
                    editFlag: "",
                    selectedTimeZone: { label: this.state.currentCustomer.time_zone, id: timeZoneId },
                    is_translation: trimCapitalize(this.state.currentCustomer.is_translation) === APPLICATION_MODES.YES,
                    is_accuracy: trimCapitalize(this.state.currentCustomer.is_accuracy) === APPLICATION_MODES.YES,
                    is_action_trackable: trimCapitalize(this.state.currentCustomer.is_action_trackable) === APPLICATION_MODES.YES,
                    is_response_trackable: trimCapitalize(this.state.currentCustomer.is_response_trackable) === APPLICATION_MODES.YES,
                    is_source_weightage: trimCapitalize(this.state.currentCustomer.is_source_weightage) === APPLICATION_MODES.YES,
                    is_curse_word: trimCapitalize(this.state.currentCustomer.is_curse_word) === APPLICATION_MODES.YES,
                    is_campaignable: trimCapitalize(this.state.currentCustomer.is_campaignable) === APPLICATION_MODES.YES,
                    is_chatbot: trimCapitalize(this.state.currentCustomer.is_chatbot) === APPLICATION_MODES.YES,
                });
                this.setSubscriptionBatch(this.state.currentCustomer.subscription_batch);
                this.props.currentCustomerFormState(APPLICATION_MODES.VIEW);
            } else {
                if (!this.state.notDiscard) {
                    this.setState({
                        name: "",
                        id: "",
                        industry: "",
                        industry_classification: "",
                        parent_id: "",
                        category: "",
                        license_type: "",
                        status: APPLICATION_MODES.INACTIVE,
                        logo: "",
                        isActive: false,
                        isCorporate: false,
                        editFlag: "",
                        valid_from: null,
                        valid_upto: null,
                        execution_batch: "",
                        selectedIndustry: [],
                        selectedIndustryClassification: [],
                        selectedParentProperty: [],
                        selectedAdditionalIndustry: [],
                        selectedLicense: [],
                        selectedTimeZone: [],
                        is_translation: "",
                        is_accuracy: "",
                        selectedStatus: [],
                        selectedCountry: [],
                        selectedState: [],
                        selectedDefaultReport: [],
                        selectedRatingType: { label: "", value: "" },
                        selectedPredictoDefaultReport: [],
                        website: "",
                        address: "",
                        is_action_trackable: "",
                        is_response_trackable: "",
                        is_source_weightage: "",
                        is_curse_word: "",
                        is_campaignable: "",
                        max_capacity: "",
                        predicto_batch: "",
                        predicto_percentage: "",
                        inapp_subscription_batch: "",
                        predicto_subscription_batch: "",
                        is_chatbot: "",
                        isValidDate: true,
                        selectedDatabaseName: { label: "", value: "" },
                    });
                }

            }
        } else if (data === "ok" && !this.state.isModalClose) {
            this.setState({
                isModalClose: true
            }, () => {
                this._triggerSubmit();
            });
        }

        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };


    _handleInput = e => {
        if (e.target.id === "id") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^[a-zA-Z0-9]{1,30}$/g)) {
                return false;
            }
        } else if (e.target.name === "name") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^\S.*$/)) {
                return false
            }
        } else if (e.target.id === "status") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;

            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        } else if (e.target.id === "corporate") {
            const corporate = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;

            this.setState({
                isCorporate: e.target.checked,
                corporate,
                editFlag: {
                    corporate: true
                }
            });
        } else if (e.target.id === "is_translation") {

            this.setState({
                is_translation: e.target.checked,
                editFlag: {
                    is_translation: true
                }
            });
        } else if (e.target.id === "is_accuracy") {

            this.setState({
                is_accuracy: e.target.checked,
                editFlag: {
                    is_accuracy: true
                }
            });
        } else if (e.target.id === "is_action_trackable") {

            this.setState({
                is_action_trackable: e.target.checked,
                editFlag: {
                    is_action_trackable: true
                }
            });
        } else if (e.target.id === "is_curse_word") {
            this.setState({
                is_curse_word: e.target.checked,
                editFlag: {
                    is_curse_word: true
                }
            });
        } else if (e.target.id === "is_campaignable") {
            this.setState({
                is_campaignable: e.target.checked,
                editFlag: {
                    is_campaignable: true
                }
            });
        } else if (e.target.id === "is_response_trackable") {

            this.setState({
                is_response_trackable: e.target.checked,
                editFlag: {
                    is_response_trackable: true
                }
            });
        } else if (e.target.id === "is_source_weightage") {

            this.setState({
                is_source_weightage: e.target.checked,
                editFlag: {
                    is_source_weightage: true
                }
            });
        } else if (e.target.id === "is_chatbot") {
            this.setState({
                is_chatbot: e.target.checked,
                editFlag: {
                    is_chatbot: true
                }
            });
        } else if (e.target.name === "execution_batch" || e.target.name === "predicto_batch") {

            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^[a-zA-Z0-9_]{1,30}$/g)) {
                return;
            }

        } else if (e.target.name === "max_capacity") {
            if (e.target.value !== "" && !Number(e.target.value)) {
                return;
            }
        } else if (e.target.name === "predicto_percentage") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^\d+(\.\d{0,2})?$/g)) {
                return;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                [e.target.name]: true
            }
        });
        this.props.editFlag(true);
    };

    _handleAppSelect = e => {
        if (e.target.id === "is_inapp") {
            this.setState({
                is_inapp: e.target.checked,
                editFlag: {
                    [e.target.id]: true
                }
            });
            this.props.selectedApplication({
                is_inapp: e.target.checked,
                is_predicto: this.state.is_predicto,
                is_blms: this.state.is_blms
            });
        } else if (e.target.id === "is_predicto") {
            this.setState({
                is_predicto: e.target.checked,
                editFlag: {
                    [e.target.id]: true
                }
            });
            this.props.selectedApplication({ is_predicto: e.target.checked, is_inapp: this.state.is_predicto })
        } else if (e.target.id === "is_blms") {
            this.setState({
                is_blms: e.target.checked,
                editFlag: {
                    [e.target.id]: true
                }
            });
            this.props.selectedApplication({ is_blms: e.target.checked, is_inapp: this.state.is_blms });
        }
    }

    _triggerImageUpload = () => {
        this.setState({
            imageCropModal: true
        });
    };

    _handleImageUpload = e => {
        const { files } = e.target;
        let validExtensions = ["jpg", "jpeg", "png"];
        let extension = files[0].name.split(".")[1].toLowerCase();
        if (validExtensions.indexOf(extension) > -1) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = ev => {
                this.setState({
                    logo: ev.target.result,
                    imageCropModal: true,
                    editFlag: {
                        status: true
                    }
                });
            };
        } else {
            this.setState({
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.ACCEPTED_IMAGE_FORMATS
            });
        }
    };

    _handleLicenseStartDateInput = e => {
        let datefrom = e != null ? e.toDateString().replace('-', '').split(" ") : "";
        this.setState({
            valid_from: e != null ? datefrom[2] + "-" + datefrom[1] + "-" + datefrom[3] : "",
            min_Date: e != null ? datefrom[2] + "-" + datefrom[1] + "-" + datefrom[3] : "",
            editFlag: {
                ...this.state.editFlag,
                startDate: true
            }
        });
        this.props.editFlag(true);
    };

    _handleLicenseEndDateInput = e => {
        let dateupto = e != null ? e.toDateString().replace('-', '').split(" ") : "";
        this.setState({
            valid_upto: e != null ? dateupto[2] + "-" + dateupto[1] + "-" + dateupto[3] : "",
            editFlag: {
                ...this.state.editFlag,
                endDate: true
            }
        });
        this.props.editFlag(true);
    };
    _handleCheckIsMandetoryFieldFilled = () => {

        const {
            id,
            name,
            selectedIndustry,
            selectedIndustryClassification,
            selectedParentProperty,
            selectedLicense,
            valid_from,
            valid_upto,
            selectedTimeZone,
            address,
            selectedStatus,
            selectedCountry,
            selectedState,
            selectedCategory,
            customer_support_email_id,
            is_campaignable,
            selectedDatabaseName
        } = this.state;

        if (selectedCategory.value === "Tenants") {
            return !(name && selectedStatus.length !== 0 && selectedIndustryClassification.length !== 0 && selectedParentProperty.length !== 0 && selectedDatabaseName.length !== 0);
        } else if (selectedCategory.value === "Competitor") {
            return !(id && name && selectedIndustry.length !== 0 && selectedTimeZone.length !== 0 && address && selectedStatus.length !== 0 && selectedCountry.length !== 0 && selectedState.length !== 0 && selectedDatabaseName.length !== 0);
        } else {
            return !(id && name && selectedIndustry.length !== 0 && selectedLicense.length !== 0 && valid_from && valid_upto && selectedTimeZone.length !== 0 && address && selectedStatus.length !== 0 && selectedCountry.length !== 0 && selectedState.length !== 0 && selectedDatabaseName.length !== 0);
        }

    };
    _showImgEdit = () => {
        this.setState({
            showImgEdit: !this.state.showImgEdit
        });
    };
    closeAlertBox = () => {
        this.setState({
            isAlertBoxOpen: false
        });
    };

    componentDidMount() {
        this.setState({ preLoader: true });
        this.props.actions.fetchCustomerCategory().then(() => {
            if (this.props.categoryList) {
                this.linkCategoryList(this.props);
            }
            this.props.actions.fetchAllIndustry().then(() => {
                if (this.props.sourceIndustryList && this.props.sourceIndustryList.industry) {
                    this.linkIndustryList(this.props);
                }
                this.linkLicenseList();
            });
        });

        this.props.actions.fetchAllStates().then(() => {
            this.loadCountryDropDown(this.props);
            if (this.state.applicationMode === APPLICATION_MODES.EDIT && this.props.customer && this.props.customer.country) {
                this.loadStateDropDown(this.props, this.props.customer.country);
            }
        });

        this.props.actions.fetchAllReports().then(() => {
            if (this.props.Reports) {
                this.loadReportsList(this.props.Reports)
            }
        });

        this.props.actions.fetchAllTimeZones().then(() => {
            if (this.props.timeZoneList && Object.keys(this.props.timeZoneList).length > 0) {
                this.loadTimeZoneList(this.props);
            }
        });

        let selectedCategory = [];
        let categoryIsDisabled = false;
        if (this.props.location.state.fromCategoryPage) {
            selectedCategory = {
                label: this.props.location.state.fromCategoryPage,
                value: this.props.location.state.fromCategoryPage
            };
            if (this.props.location.state.fromCategoryPage !== APPLICATION_MODES.LIVE) {
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    categoryIsDisabled = true;
                }
            } else {
                categoryIsDisabled = true;
            }

            this.setState({
                selectedCategory: selectedCategory,

            });
        } else {
            if (this.props.customer) {
                if (this.props.customer.category === APPLICATION_MODES.LIVE) {
                    categoryIsDisabled = true;
                }
            }
        }

        if (this.props.customer) {
            this.setSubscriptionBatch(this.props.customer.subscription_batch);
        }


        this.setState({
            categoryIsDisabled: categoryIsDisabled,
            applicationMode: this.props.applicationMode,
            currentCustomer: this.props.currentCustomer,
            ...this.props.customer,
            parent_customer: this.props.currentCustomer && this.props.currentCustomer.parent_customer,
            status: this.props.customer ? this.props.customer.status : "",
            selectedStatus: this.props.customer && this.props.customer.status ? {
                label: this.props.customer.status,
                value: this.props.customer.status
            } : [],
            isCorporate: this.state.applicationMode === APPLICATION_MODES.CREATE ? this.props.location.state.isGroupInstance : this.props.customer
                ? trimCapitalize(this.props.customer.corporate) === APPLICATION_MODES.YES
                : false,
            is_translation: this.props.customer ? trimCapitalize(this.props.customer.is_translation) === APPLICATION_MODES.YES : false,
            is_accuracy: this.props.customer ? trimCapitalize(this.props.customer.is_accuracy) === APPLICATION_MODES.YES : false,
            is_action_trackable: this.props.customer ? trimCapitalize(this.props.customer.is_action_trackable) === APPLICATION_MODES.YES : false,
            is_response_trackable: this.props.customer ? trimCapitalize(this.props.customer.is_response_trackable) === APPLICATION_MODES.YES : false,
            is_source_weightage: this.props.customer ? trimCapitalize(this.props.customer.is_source_weightage) === APPLICATION_MODES.YES : false,
            is_curse_word: this.props.customer ? trimCapitalize(this.props.customer.is_curse_word) === APPLICATION_MODES.YES : false,
            selectedCountry: this.props.customer && this.props.customer.country ? {
                label: this.props.customer.country,
                value: this.props.customer.country
            } : [],
            selectedState: this.props.customer && this.props.customer.state ? {
                label: this.props.customer.state,
                value: this.props.customer.state
            } : [],
            selectedDefaultReport: this.props.customer && this.props.customer.inapp_default_landing_page ? {
                label: this.props.customer.inapp_default_landing_page,
                value: this.props.customer.inapp_default_landing_page_id
            } : [],
            selectedPredictoDefaultReport: this.props.customer && this.props.customer.predicto_default_landing_page ? {
                label: this.props.customer.predicto_default_landing_page,
                value: this.props.customer.predicto_default_landing_page_id
            } : [],
            selectedRatingType: this.props.customer && this.props.customer.rating_type ?
                this.state.ratingTypeListOption.find(option =>
                    option.value === this.props.customer.rating_type ||
                    option.label === this.props.customer.rating_type
                ) :
                {
                    label: 'Tatvam Rating',
                    value: 'tatvam_rating'
                },


            selectedDatabaseName: this.props.customer && this.props.customer.database_name ?
                this.state.DatabaseListOption.find(option =>
                    option.value === this.props.customer.database_name ||
                    option.label === this.props.customer.database_name
                ) : [],
            is_campaignable: this.props.customer ? trimCapitalize(this.props.customer.is_campaignable) === APPLICATION_MODES.YES : false,
            max_capacity: this.props.customer && this.props.customer.max_capacity > 0 ? this.props.customer.max_capacity.toString() : "",
            is_predicto: this.props.is_predicto ? this.props.is_predicto : false,
            is_inapp: this.props.is_inapp ? this.props.is_inapp : false,
            is_blms: this.props.is_blms ? this.props.is_blms : false,
            is_chatbot: this.props.customer ? trimCapitalize(this.props.customer.is_chatbot) === APPLICATION_MODES.YES : false,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedParentProperty, is_source_weightage, is_action_trackable, is_response_trackable, is_campaignable } = this.state;
        if (this.props.customer && this.props.customer !== prevProps.customer) {
            this.linkParentPropertyList(this.props);
        };


        if (selectedParentProperty.value && selectedParentProperty.value !== prevState.selectedParentProperty.value) {
            if (this.state.applicationMode === APPLICATION_MODES.EDIT || this.state.applicationMode === APPLICATION_MODES.VIEW) {
                this.props.actions.fetchAllIndustryClassification(selectedParentProperty.value).then((response) => {
                    this.linkIndustryClassificationList(response.data.data);
                });
            }
        }

        // Check if switch button states have changed
        const isStateChanged =
            is_source_weightage !== prevState.is_source_weightage ||
            is_action_trackable !== prevState.is_action_trackable ||
            is_response_trackable !== prevState.is_response_trackable ||
            is_campaignable !== prevState.is_campaignable;

        if (isStateChanged) {
            this.loadReportsList(this.props.Reports);
        }
    }

    setSubscriptionBatch = (subscription_batch) => {
        let inapp_subscription_batch_name = "";
        let predicto_subscription_batch_name = "";
        if (subscription_batch && subscription_batch.length > 0) {
            let inApp_batch = subscription_batch.filter(item => item.application_name === APPLICATION_MODES.TATVAM_INAPP);
            if (inApp_batch.length > 0) {
                inapp_subscription_batch_name = inApp_batch[0].batch_name;
            }

            inApp_batch = subscription_batch.filter(item => item.application_name === APPLICATION_MODES.PREDICTO);
            if (inApp_batch.length > 0) {
                predicto_subscription_batch_name = inApp_batch[0].batch_name;
            }
        }
        this.setState({
            inapp_subscription_batch: inapp_subscription_batch_name,
            predicto_subscription_batch: predicto_subscription_batch_name
        });

    }

    linkIndustryList = (thisProps) => {
        const industryLst = [];
        let industryRawData = thisProps.sourceIndustryList.industry;
        if (industryRawData) {
            industryRawData.map((industry) => {
                if (industry.status === APPLICATION_MODES.ACTIVE) {
                    industryLst.push({ label: industry.name, value: industry.name })
                }
            });
            industryLst.sort(dynamicSort("label"));
        }
        this.setState({
            industryListOptions: industryLst
        });
    };

    linkIndustryClassificationList = (thisPropsList) => {
        const industryClassificationLst = [];
        if (thisPropsList) {
            thisPropsList.map((industry) => {
                industryClassificationLst.push({ label: industry.name, value: industry.name })
            });
            industryClassificationLst.sort(dynamicSort("label"));
        }
        this.setState({
            industryClassificationListOptions: industryClassificationLst
        });
    };

    linkParentPropertyList = (thisProps) => {
        const propertyListData = [];
        let data = Object.values(JSON.parse(JSON.stringify(thisProps.customerList)));
        if (data.length) {
            data.map((ele) => {
                if (ele.category !== "Competitor" && ele.category !== "Tenants") {
                    if (ele.status && ele.status !== APP_STATUS.INACTIVE)
                        propertyListData.push({ label: (ele.id + " - " + ele.name), value: ele.id });
                }
            });
            propertyListData.sort(dynamicSort("label"));
        }

        this.setState({
            propertyListOption: propertyListData
        });
    };

    linkCategoryList = (thisProps) => {
        const categoryLst = [];
        const limitedCategoryList = [];
        if (this.props.categoryList) {
            this.props.categoryList.map((data) => {
                categoryLst.push({ label: data, value: data });
            });
            categoryLst.sort(dynamicSort("label"));
            this.props.categoryList.map((data) => {
                if (data === "Demo" || data === "Live") {
                    limitedCategoryList.push({ label: data, value: data });
                }
            });
        }
        this.setState({
            categoryListOptions: categoryLst,
            categoryListOptionsLimited: limitedCategoryList
        });

    };


    linkLicenseList = () => {
        const licenseLst = [];
        if (this.licenseList) {
            this.licenseList.map((data) => {
                licenseLst.push({ label: data, value: data });
            });
            licenseLst.sort(dynamicSort("label"));
        }
        this.setState({
            licenseTypeListOptions: licenseLst,
            preLoader: false
        });

    };

    _handleIndustrySelect = selectedOption => {
        this.setState({ selectedAdditionalIndustry: [] });
        const allIndustry = this.state.industryListOptions;
        const addIndList = allIndustry.filter((fetchRel) => {
            return fetchRel.label !== selectedOption.label
        });


        this.setState({
            editFlag: {
                ...this.state.editFlag,
                industry: true
            },
            selectedIndustry: selectedOption,
            addIndustryListOptions: addIndList
        });
        this.props.editFlag(true);
    };

    _handleIndustryClassificationSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                industry_classification: true
            },
            selectedIndustryClassification: selectedOption,
        });
        this.props.editFlag(true);
    };

    __handleParentPropertySelect = (selectedOption) => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                parent_id: true
            },
            selectedParentProperty: selectedOption,
            selectedIndustryClassification: []
        });

        this.props.actions.fetchAllIndustryClassification(selectedOption.value).then((response) => {
            this.linkIndustryClassificationList(response.data.data);
        });

        this.props.editFlag(true);
    };

    _handleStatusSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                status: true
            },
            selectedStatus: selectedOption
        });
        this.props.editFlag(true);
    };

    _handleAdditionalIndustrySelect = selectedOption => {
        let currentOptions = [];
        if (this.state.selectedAdditionalIndustry && this.state.selectedAdditionalIndustry.length > 1) {
            currentOptions = selectedOption;
        } else if (this.state.selectedAdditionalIndustry && this.state.selectedAdditionalIndustry.length === 1 && selectedOption != null) {
            currentOptions = this.state.selectedAdditionalIndustry;
        } else if (selectedOption == null) {
            currentOptions = [];
        }

        if (this.props.applicationMode !== APPLICATION_MODES.CREATE) {
            const allIndustry = this.props.sourceIndustryList.industry.map((indus) => {
                return { label: indus.name, value: indus.name }
            });
            const addIndList = allIndustry.filter((fetchRel) => {
                return fetchRel.label !== this.props.customer.industry
            });

            const additionalIndustryList = addIndList.filter(({ label: id1 }) => !currentOptions.some(({ label: id2 }) => id2 === id1));
            additionalIndustryList.sort(dynamicSort("label"));
            this.setState({ addIndustryListOptions: additionalIndustryList });
        }

        this.setState({
            editFlag: {
                ...this.state.editFlag,
                industry: true
            },
            selectedAdditionalIndustry: selectedOption
        });
        this.props.editFlag(true);
    };


    _handleCategorySelect = selectedOption => {
        this.setState({
            valid_from: "",
            valid_upto: "",
            selectedLicense: { label: "Please Select", value: "" }
        })

        this.setState({
            editFlag: {
                ...this.state.editFlag,
                category: true
            },
            selectedCategory: selectedOption
        });
        this.props.editFlag(true);
    };


    _handleLicenseSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                license_type: true
            },
            selectedLicense: selectedOption
        });
        this.props.editFlag(true);
    };


    groupInstanceStatusDisabled = () => {
        if (this.props.applicationMode !== APPLICATION_MODES.CREATE) {
            if (this.state.isCorporate) {
                return !((this.state.currentCustomer.children === undefined || this.state.currentCustomer.children.length === 0)
                    && (this.state.roles === undefined || this.state.roles.length === 0)
                    && (this.state.users === undefined || this.state.users.length === 0));
            } else {
                return !((this.state.currentCustomer && (this.state.currentCustomer.children === undefined || this.state.currentCustomer.children.length === 0))
                    && (this.state.sources === undefined || this.state.sources.length === 0)
                    && (this.state.roles === undefined || this.state.roles.length === 0)
                    && (this.state.users === undefined || this.state.users.length === 0)
                    && (this.state.rules === undefined || this.state.rules.length === 0)
                    && (this.state.competitors === undefined || this.state.competitors.length === 0)
                    && (this.state.tenants === undefined || this.state.tenants.length === 0)
                    && (this.state.dimensions === undefined || (this.state.dimensions.length > 0 && this.state.dimensions.filter(item => item.customer_id !== "0000").length === 0))
                    && (this.state.dimensionValues === undefined || this.state.dimensionValues.length === 0))
            }

        }
        {
            return false;
        }
    }

    componentWillReceiveProps(props) {
        if (props.sourceIndustryList && props.sourceIndustryList.industry) {
            this.linkIndustryList(props);
        }

        if (props.timeZoneList && Object.keys(props.timeZoneList).length > 0) {
            this.loadTimeZoneList(props);
        }
        let categoryIsDisabled = false;
        if (props.location.state.fromCategoryPage) {
            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                categoryIsDisabled = true;
            }
        }

        if (props.states) {
            this.loadCountryDropDown(props);
            if (this.state.applicationMode === APPLICATION_MODES.EDIT && props.customer && props.customer.country) {
                this.loadStateDropDown(props, props.customer.country);
            }
        }

        if (this.props.Reports) {
            this.loadReportsList(this.props.Reports)
        }
        if (props.applicationMode !== APPLICATION_MODES.CREATE && !this.state.selectedParentProperty.length && !this.state.selectedParentProperty.value) {
            if (this.props.customerList && Object.values(JSON.parse(JSON.stringify(this.props.customerList)))) {
                let selectedParentPropertyTemp = [];
                selectedParentPropertyTemp = { label: this.props.currentCustomer.parent_id + " - " + this.props.currentCustomer.parent_name, value: this.props.currentCustomer.parent_id };
                this.setState({
                    selectedParentProperty: selectedParentPropertyTemp
                })
            }
        };

        if (props.customer && props.applicationMode !== APPLICATION_MODES.EDIT) {
            let selectedCategory = [];
            if (props.customer.category) {
                selectedCategory = { label: props.customer.category, value: props.customer.category };
            } else if (props.location.state.fromCategoryPage) {
                selectedCategory = {
                    label: props.location.state.fromCategoryPage,
                    value: props.location.state.fromCategoryPage
                };
            }

            let selectedLicense = [];
            if (props.customer.license_type) {
                selectedLicense = { label: props.customer.license_type, value: props.customer.license_type };
            }
            let selectedIndustry = [];
            let selectedAdditionalIndustry = [];
            if (props.sourceIndustryList && props.sourceIndustryList.industry) {
                props.sourceIndustryList.industry.map((industry) => {
                    if (industry.name === props.customer.industry) {
                        selectedIndustry = { label: industry.name, value: industry.name };
                    }
                });
            }

            let selectedIndustryClassification = [];
            if (props.industryClassificationList && props.industryClassificationList) {
                props.industryClassificationList.map((industry) => {
                    if (industry.name === props.customer.industry_classification) {
                        selectedIndustryClassification = { label: industry.name, value: industry.name };
                    }
                });
            }

            if (props.customer && props.customer.additionalIndustry) {
                selectedAdditionalIndustry = props.customer.additionalIndustry.map((addInd) => {
                    return { label: addInd, value: addInd };
                });
            }

            if (props.customer) {
                this.setSubscriptionBatch(props.customer.subscription_batch);
            }

            let additionalIndustryList = [];
            if (props.sourceIndustryList && props.sourceIndustryList.industry) {
                const allIndustry = props.sourceIndustryList.industry.map((indus) => {
                    return { label: indus.name, value: indus.name }
                });
                const addIndList = allIndustry.filter((fetchRel) => {
                    return fetchRel.label !== props.customer.industry
                });
                additionalIndustryList = addIndList.filter(({ label: id1 }) => !selectedAdditionalIndustry.some(({ label: id2 }) => id2 === id1));
                additionalIndustryList.sort(dynamicSort("label"));
            }
            let selectedTimeZone = [];
            if (props.timeZoneList && Object.keys(props.timeZoneList).length > 0) {
                let data;
                for (data of Object.values(this.props.timeZoneList)) {
                    if (data.time_zone === props.customer.time_zone) {
                        selectedTimeZone = { label: data.time_zone, value: data.id };
                        break;
                    }
                }
            }

            this.setState({
                selectedCategory: selectedCategory,
                selectedLicense: selectedLicense,
                selectedIndustry: selectedIndustry,
                selectedIndustryClassification: selectedIndustryClassification,
                selectedAdditionalIndustry: selectedAdditionalIndustry,
                addIndustryListOptions: additionalIndustryList,
                copiedAdditionalIndustry: selectedAdditionalIndustry,
                selectedTimeZone
            });
        }

        this.setState({
            categoryIsDisabled: categoryIsDisabled,
            applicationMode: this.state.applicationMode ? this.state.applicationMode : props.applicationMode,
            currentCustomer: props.currentCustomer,
            ...props.customer,
            categoryList: props.categoryList.sort(),
            parent_customer: props.currentCustomer && props.currentCustomer.parent_customer,
            selectedStatus: props.customer && props.customer.status ? {
                label: props.customer.status,
                value: props.customer.status
            } : [],
            isCorporate: this.state.applicationMode === APPLICATION_MODES.CREATE ? props.location.state.isGroupInstance : props.customer ? trimCapitalize(props.customer.corporate) === APPLICATION_MODES.YES : false,
            is_translation: props.customer ? trimCapitalize(props.customer.is_translation) === APPLICATION_MODES.YES : false,
            is_accuracy: props.customer ? trimCapitalize(props.customer.is_accuracy) === APPLICATION_MODES.YES : false,
            is_action_trackable: props.customer ? trimCapitalize(props.customer.is_action_trackable) === APPLICATION_MODES.YES : false,
            is_response_trackable: props.customer ? trimCapitalize(props.customer.is_response_trackable) === APPLICATION_MODES.YES : false,
            is_source_weightage: props.customer ? trimCapitalize(props.customer.is_source_weightage) === APPLICATION_MODES.YES : false,
            is_curse_word: props.customer ? trimCapitalize(props.customer.is_curse_word) === APPLICATION_MODES.YES : false,
            selectedCountry: props.customer && props.customer.country ? {
                label: props.customer.country,
                value: props.customer.country
            } : [],
            selectedState: props.customer && props.customer.state ? {
                label: props.customer.state,
                value: props.customer.state
            } : [],
            selectedDefaultReport: props.customer && props.customer.inapp_default_landing_page ? {
                label: props.customer.inapp_default_landing_page,
                value: props.customer.inapp_default_landing_page_id
            } : [],
            selectedPredictoDefaultReport: this.props.customer && this.props.customer.predicto_default_landing_page ? {
                label: this.props.customer.predicto_default_landing_page,
                value: this.props.customer.predicto_default_landing_page_id
            } : [],
            // selectedParentProperty:props.customer && props.customer.parent_id ? {
            //     label: props.customer.parent_id + ' - ' + props.customer.parent_name,
            //     value: props.customer.parent_id
            // }:this.state.selectedParentProperty,
            selectedRatingType: this.props.customer && this.props.customer.rating_type ?
                this.state.ratingTypeListOption.find(option =>
                    option.value === this.props.customer.rating_type ||
                    option.label === this.props.customer.rating_type
                ) :
                {
                    label: 'Tatvam Rating',
                    value: 'tatvam_rating'
                },

            selectedDatabaseName: this.props.customer && this.props.customer.database_name ?
                this.state.DatabaseListOption.find(option =>
                    option.value === this.props.customer.database_name ||
                    option.label === this.props.customer.database_name
                ) : [],
            is_campaignable: props.customer ? trimCapitalize(props.customer.is_campaignable) === APPLICATION_MODES.YES : false,
            max_capacity: props.customer && props.customer.max_capacity > 0 ? props.customer.max_capacity.toString() : "",
            is_predicto: props.customer && props.customer.is_predicto ? props.customer.is_predicto : false,
            is_inapp: props.customer && props.customer.is_inapp ? props.customer.is_inapp : false,
            is_blms: props.customer && props.customer.is_blms ? props.customer.is_blms : false,
            is_chatbot: props.customer ? trimCapitalize(props.customer.is_chatbot) === APPLICATION_MODES.YES : false,
        });
    }

    _handleTimeZoneSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                timezone: true
            },
            selectedTimeZone: selectedOption,
            isModalClose: (this.state.applicationMode === APPLICATION_MODES.EDIT && this.props.customer.time_zone !== selectedOption.label) ? false : true

        });
        this.props.editFlag(true);
    };

    loadTimeZoneList = (thisProps) => {
        const timeZoneLst = [];
        for (let data of Object.values(this.props.timeZoneList)) {
            if (data.status === APPLICATION_MODES.ACTIVE) {
                timeZoneLst.push({ label: data.time_zone, value: data.id })
            }
        }
        timeZoneLst.sort(dynamicSort("label"));
        this.setState({
            timeZoneListOptions: timeZoneLst
        });
    };

    closeCropModal = () => {
        this.setState({
            imageCropModal: false
        });
    };

    setImageData = (imageData) => {
        this.setState({
            logo: imageData,
            imageCropModal: false,
            editFlag: {
                status: true
            }
        });
    }

    _handleTimeZoneSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                timezone: true
            },
            selectedTimeZone: selectedOption,
            isModalClose: (this.state.applicationMode === APPLICATION_MODES.EDIT && this.props.customer.time_zone !== selectedOption.label) ? false : true

        });
        this.props.editFlag(true);
    };


    loadCountryDropDown = (thisProps) => {
        const countryLst = [];
        if (thisProps.states) {
            const map = new Map();
            Object.values(thisProps.states).map((data) => {
                if (!map.has(data.country_name)) {
                    map.set(data.country_name, true);
                    countryLst.push({ label: data.country_name, value: data.country_name });
                }
            });
            countryLst.sort(dynamicSort("label"));
        }
        this.setState({
            countryListOptions: countryLst
        });

    };

    loadStateDropDown = (thisProps, countryName) => {
        const statesLst = [];
        if (thisProps.states) {
            const statesData = Object.values(thisProps.states).filter(item => {
                return item.country_name === countryName
            });
            if (statesData.length > 0) {
                statesData.map((data) => {
                    statesLst.push({ label: data.name, value: data.name });
                });
                statesLst.sort(dynamicSort("label"));
            }
        }
        this.setState({
            stateListOptions: statesLst
        });

    };

    loadReportsList = (propsReport) => {
        const excludeReportsInCreateMode = [
            "Survey Feedback",
            "Survey Dashboard",
            "Tatvam Rewards",
            "Tenants Management",
            "Customer Feedback Dashboard",
            "Customer Feedback Dashboard - Details"
        ];

        let inapp_reports;

        if (this.state.isCorporate === false) {
            inapp_reports = propsReport.filter((report) => report.application_name === APPLICATION_MODES.TATVAM_INAPP && report.status === APP_STATUS.ACTIVE && !report.customer_type.includes("Group Instance") && (this.state.applicationMode !== APPLICATION_MODES.CREATE || !excludeReportsInCreateMode.includes(report.name)))
                .map((report) => ({
                    label: report.name,
                    value: report.id
                })).sort((a, b) => a.label.localeCompare(b.label));
        } else {
            inapp_reports = propsReport.filter((report) => report.application_name === APPLICATION_MODES.TATVAM_INAPP && report.status === APP_STATUS.ACTIVE &&
                (!report.customer_type.includes('Live') || !report.customer_type.includes('Demo') || !report.customer_type.includes('Competitor')) &&
                (this.state.applicationMode !== APPLICATION_MODES.CREATE || !excludeReportsInCreateMode.includes(report.name))
            ).map((report) => ({
                label: report.name,
                value: report.id
            })).sort((a, b) => a.label.localeCompare(b.label));
        }

        if (!this.state.is_source_weightage) {
            inapp_reports = inapp_reports.filter(report => report.label !== PROPERTY_ROLES.SOURCE_WAITHAGE && report.label !== PROPERTY_ROLES.KPI_DASHBOARD_360);
        }
        if (!this.state.is_action_trackable) {
            inapp_reports = inapp_reports.filter(report => report.label !== PROPERTY_ROLES.TICKET_DASHBOARD);
        }
        if (!this.state.is_response_trackable) {
            inapp_reports = inapp_reports.filter(report => report.label !== PROPERTY_ROLES.RESPONSE_DASHBOARD);
        }
        if (!this.state.is_campaignable) {
            inapp_reports = inapp_reports.filter(report => report.label !== PROPERTY_ROLES.FEEDBACK_CAMPAIGNS && report.label !== PROPERTY_ROLES.SURVEY_DESIGNER);
        }

        this.setState({
            reportsList: inapp_reports,
        });

        const predicto_reports = propsReport.filter((report) => report.application_name === APPLICATION_MODES.PREDICTO && report.status === APP_STATUS.ACTIVE)
            .map((report) => ({
                label: report.name,
                value: report.id
            })).sort((a, b) => a.label.localeCompare(b.label));

        this.setState({
            predictoReportList: predicto_reports
        });
    };


    _handleCountrySelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                country: true
            },
            selectedCountry: selectedOption,
            selectedState: []
        });
        this.loadStateDropDown(this.props, selectedOption.label);
        this.props.editFlag(true);
    };

    _handleStateSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                state: true
            },
            selectedState: selectedOption
        });
        this.props.editFlag(true);
    };

    _handleDefaultReportChange = optionsList => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                state: true
            },
            selectedDefaultReport: optionsList
        });
        this.props.editFlag(true);
    }

    _handleRatingTypeChange = optionsList => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                state: true
            },
            selectedRatingType: optionsList
        });
        this.props.editFlag(true);
    }

    _handleDatabaseNameChange = optionsList => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                state: true
            },
            selectedDatabaseName: optionsList
        });
        this.props.editFlag(true);
    }

    _handlePredictoDefaultReportChange = optionsList => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                state: true
            },
            selectedPredictoDefaultReport: optionsList
        });
        this.props.editFlag(true);
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat" />}
                    </TatvamCol>
                </Row>
                <TatvamCol className="z-depth-2 centered mt-1 form-wrapper" s={12} m={12} l={12} xl={12}>
                    <TatvamCol s={12} className="mt-0 ml-1 mb-1">
                        {this.state.applicationMode === APPLICATION_MODES.CREATE ? (
                            <h5 className="col s12 m6">{PROPERTY.ADD_PROPERTY}</h5>) :
                            this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                                <h5 className="col s12 m6">{PROPERTY.EDIT_PROPERTY}</h5>) :
                                this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                                    <h5 className="col s12 m6">{PROPERTY.VIEW_PROPERTY}</h5>) : ""}
                    </TatvamCol>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (

                        <Row className="project-form">
                            <Col>
                                {/*logo_Image*/}
                                <TatvamCol s={2} m={2} l={2} xl={2} className="project_name mt-1 mb-1 property_img">
                                    {this.state.logo === "" ? (
                                        <TatvamButton
                                            className="image-upload "
                                            onClick={this._triggerImageUpload}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                            title={PROPERTY.UPLOAD_LOGO}
                                        >
                                            <input
                                                type="file"
                                                ref="logoUpload"
                                                onChange={e => this._handleImageUpload(e)}
                                                className="hide"
                                                accept="image/*"
                                            />
                                        </TatvamButton>
                                    ) :
                                        (
                                            <div className="col">
                                                <div className="left d-flex image-uploaded mb-2">
                                                    <img className="responsive-img" src={this.state.logo} alt="logo"
                                                        title="Property Logo" />
                                                </div>
                                                {(this.state.applicationMode === APPLICATION_MODES.EDIT ||
                                                    this.state.applicationMode === APPLICATION_MODES.CREATE) &&
                                                    (
                                                        <div>
                                                            <span className="logo_edit material-icons"
                                                                onClick={this._triggerImageUpload}> mode_edit</span>
                                                            <input
                                                                type="file"
                                                                ref="logoUpload"
                                                                onChange={e => this._handleImageUpload(e)}
                                                                className="hide"
                                                                accept="image/*"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                </TatvamCol>
                            </Col>

                            <Col>
                                <form onSubmit={this._handleSave} className="multi_select_height">

                                    <TatvamCol s={12} className="formRow  mt-2">
                                        <TatvamCol s={12} m={12}>
                                            <TatvamCol m={4} className="p-0 mt-1">
                                                <label className="status-label pl-3">
                                                    {PROPERTY.VIEW_PROPERTY_ID}
                                                    {this.state.selectedCategory.value !== "Tenants" && (
                                                        <span style={{ color: 'red' }}> *</span>
                                                    )}
                                                </label>
                                                <TatvamTextBox
                                                    className={(this.state.inValidForm && !this.state.id) ? "labelText mt-0 project_name invalid select-ht required-field" : (this.state.inValidForm && this.state.id === "") ? "labelText mt-0 project_name invalid select-ht required-field" : "labelText mt-0 project_name select-ht required-field"}
                                                    m={12}
                                                    name="id"
                                                    id="id"
                                                    onChange={this._handleInput}
                                                    value={this.state.id}
                                                    disabled={(this.state.applicationMode !== APPLICATION_MODES.CREATE)}
                                                    autoComplete="off"
                                                    maxLength={10}
                                                />
                                            </TatvamCol>

                                            <TatvamCol m={4} className="p-0 mt-1">
                                                <label className="status-label pl-3">{PROPERTY.VIEW_PROPERTY_NAME}<span
                                                    style={{ color: 'red' }}> *</span></label>
                                                <TatvamTextBox
                                                    className={(this.state.inValidForm && (!this.state.name || this.state.name === "")) ? "labelText mt-0 project_name invalid select-ht p-0 m-0" : "labelText mt-0 project_name select-ht p-0 m-0"}
                                                    m={12}
                                                    name="name"
                                                    id="name"
                                                    onChange={this._handleInput}
                                                    value={this.state.name}
                                                    disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    autoComplete="off"
                                                    maxLength={255}
                                                />
                                            </TatvamCol>

                                            {this.state.selectedCategory.value === "Tenants" && (
                                                <TatvamCol m={4} xl={4} className="mb-2 select-field mt-1">
                                                    <label htmlFor="parent_id">{"Parent Property"}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && this.state.selectedParentProperty.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedParentProperty}
                                                        name="parent_id"
                                                        id="parent_id"
                                                        onChange={this.__handleParentPropertySelect}
                                                        options={this.state.propertyListOption}
                                                        placeholder={"Select Parent Property"}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>)}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol m={4} xl={4} className="mb-2 select-field mt-1">
                                                    <label htmlFor="industry">{PROPERTY.VIEW_INDUSTRY}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && this.state.selectedIndustry.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedIndustry}
                                                        name="industry"
                                                        id="industry"
                                                        onChange={this._handleIndustrySelect}
                                                        options={this.state.industryListOptions}
                                                        placeholder={PROPERTY.SELECT_INDUSTRY}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>)}

                                        </TatvamCol>

                                        <TatvamCol s={12} m={12} className="mt-1">
                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol m={4} xl={4} className="select-field mt-1">
                                                    <label htmlFor="industry">{PROPERTY.ADDITIONAL_INDUSTRY}</label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className="validate"
                                                        isMulti
                                                        value={this.state.selectedAdditionalIndustry}
                                                        name="additional_industry"
                                                        id="additional_industry"
                                                        onChange={this._handleAdditionalIndustrySelect}
                                                        options={this.state.addIndustryListOptions}
                                                        placeholder={PROPERTY.SELECT_INDUSTRY}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        closeMenuOnSelect={false}
                                                    />
                                                </TatvamCol>
                                            )}
                                            <button type="submit" ref="submitForm" className="hide" />

                                            {this.state.selectedCategory.value === "Tenants" && (
                                                <TatvamCol m={4} xl={4} className="mb-2 select-field mt-1">
                                                    <label htmlFor="industry_classification">{PROPERTY.VIEW_INDUSTRY_CLASSIFICATION}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && this.state.selectedIndustryClassification.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedIndustryClassification}
                                                        name="industry_classification"
                                                        id="industry_classification"
                                                        onChange={this._handleIndustryClassificationSelect}
                                                        options={this.state.industryClassificationListOptions}
                                                        placeholder={'Select Industry Classification'}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>
                                            )}

                                            <TatvamCol m={4} xl={4} className="select-field mt-1">
                                                <label htmlFor="category">{PROPERTY.VIEW_CATEGORY}<span
                                                    style={{ color: 'red' }}> *</span></label>
                                                <TatvamMultiSelect
                                                    classNamePrefix="react-select"
                                                    className={(this.state.inValidForm && this.state.selectedCategory.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                    value={this.state.selectedCategory}
                                                    name="category"
                                                    id="category"
                                                    onChange={this._handleCategorySelect}
                                                    options={(this.state.isCorporate || this.props.location.state.isGroupInstance) ? this.state.categoryListOptionsLimited : this.state.categoryListOptions}
                                                    placeholder={PROPERTY.SELECT_CATEGORY}
                                                    isDisabled={this.state.categoryIsDisabled}
                                                />
                                            </TatvamCol>

                                            {/* just for UI Layout made duplicateed code for status */}
                                            {this.state.selectedCategory.value === "Tenants" &&
                                                <TatvamCol m={4} xl={4} className="mb-1 mt-1 select-field">
                                                    <label htmlFor="status">{PROPERTY.VIEW_STATUS}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && this.state.selectedStatus.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedStatus}
                                                        name="status"
                                                        id="status"
                                                        onChange={this._handleStatusSelect}
                                                        options={this.state.statusListOptions}
                                                        placeholder={PROPERTY.SELECT_STATUS}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>
                                            }

                                            {this.state.selectedCategory.value !== "Competitor" && this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol m={4} xl={4} className=" select-field mt-1">
                                                    <label htmlFor="license_type">{PROPERTY.VIEW_LICENCE_TYPE}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && (this.state.selectedLicense.length === 0 || this.state.selectedLicense.value === "")) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedLicense}
                                                        name="license_type"
                                                        id="license_type"
                                                        onChange={this._handleLicenseSelect}
                                                        options={this.state.licenseTypeListOptions}
                                                        placeholder={PROPERTY.SELECT_LICENCE}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>
                                            )}


                                        </TatvamCol>

                                        <TatvamCol s={12} m={12} className="mt-1">
                                            {/* just for UI Layout made duplicateed status */}
                                            {this.state.selectedCategory.value !== "Tenants" &&
                                                <TatvamCol m={4} xl={4} className="mb-1 mt-0 select-field">
                                                    <label htmlFor="status">{PROPERTY.VIEW_STATUS}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && this.state.selectedStatus.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedStatus}
                                                        name="status"
                                                        id="status"
                                                        onChange={this._handleStatusSelect}
                                                        options={this.state.statusListOptions}
                                                        placeholder={PROPERTY.SELECT_STATUS}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>
                                            }

                                            {this.state.selectedCategory.value !== "Competitor" && this.state.selectedCategory.value !== "Tenants" && (
                                                <Fragment>
                                                    <TatvamCol m={4}
                                                        className={(this.state.selectedCategory.value === "Competitor" || this.state.selectedCategory.value === "Tenants") ? "switch-wrapper pt-0" : "switch-wrapper pt-3 mb-2"}>
                                                        {this.state.selectedCategory.value !== "Competitor" && this.state.selectedCategory.value !== "Tenants" && (
                                                            <Fragment>
                                                                <Switch
                                                                    id="corporate"
                                                                    checked={this.state.isCorporate}
                                                                    onChange={this._handleInput}
                                                                    offLabel={PROPERTY.GROUP_INSTANCE}
                                                                    m={2}
                                                                    disabled={this.state.applicationMode === APPLICATION_MODES.VIEW || (this.groupInstanceStatusDisabled())}
                                                                />
                                                            </Fragment>
                                                        )}
                                                    </TatvamCol>
                                                    <TatvamCol m={4} className="mt-1">
                                                        <label
                                                            className="status-label">{PROPERTY.VIEW_LICENSE_START_DATE}<span
                                                                style={{ color: 'red' }}> *</span></label>
                                                        <TatvamDatePicker
                                                            selected={this.state.valid_from && new Date(this.state.valid_from)}
                                                            onChange={this._handleLicenseStartDateInput}
                                                            dateFormat="dd-MMM-yyyy"
                                                            validate
                                                            placeholderText={PROPERTY.DATE_FORMAT}
                                                            className={(this.state.inValidForm && !this.state.valid_from) ? "date-input invalid select-ht" : "date-input select-ht"}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                    </TatvamCol>
                                                    <TatvamCol m={4} className="mt-1">
                                                        <label className="status-label">{PROPERTY.VIEW_LICENSE_END_DATE}<span
                                                            style={{ color: 'red' }}> *</span></label>
                                                        <TatvamDatePicker
                                                            selected={this.state.valid_upto && new Date(this.state.valid_upto)}
                                                            onChange={this._handleLicenseEndDateInput}
                                                            minDate={this.state.min_Date && new Date(this.state.min_Date)}
                                                            disabled={!this.state.valid_from && this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            dateFormat="dd-MMM-yyyy"
                                                            validate
                                                            placeholderText={PROPERTY.DATE_FORMAT}
                                                            className={(this.state.inValidForm && !this.state.valid_upto) ? "date-input invalid select-ht" : "date-input select-ht"}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </TatvamCol>

                                                </Fragment>
                                            )}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol m={4} xl={4}
                                                    className={(this.state.selectedCategory.value === "Competitor") ? "mb-1 mt-0 select-field" : "mb-1 mt-1 select-field"}>
                                                    <label htmlFor="timezone">{PROPERTY.VIEW_TIME_ZONE}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && this.state.selectedTimeZone.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedTimeZone}
                                                        name="timezone"
                                                        id="timezone"
                                                        onChange={this._handleTimeZoneSelect}
                                                        options={this.state.timeZoneListOptions}
                                                        placeholder={PROPERTY.SELECT_INDUSTRY}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    />
                                                </TatvamCol>)}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol m={4} className="textarea-input mt-1">
                                                    <label htmlFor="address">{PROPERTY.VIEW_ADDRESS}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamTextArea
                                                        className="p-1 mt-0"
                                                        m={12}
                                                        name="address"
                                                        id="address"
                                                        onChange={this._handleInput}
                                                        className={(this.state.inValidForm && !this.state.address) ? "labelText mb-0 validate invalid ht" : "labelText mb-0 validate ht"}
                                                        value={this.state.address}
                                                        maxLength={250}
                                                    />
                                                </TatvamCol>)}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol s={12}>
                                                    <TatvamCol m={4} className="mb-1 mt-0 select-field">
                                                        <label htmlFor="country">{PROPERTY.VIEW_COUNTRY}<span
                                                            style={{ color: 'red' }}> *</span></label>
                                                        <TatvamMultiSelect
                                                            classNamePrefix="react-select"
                                                            className={(this.state.inValidForm && this.state.selectedCountry.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                            value={this.state.selectedCountry}
                                                            name="country"
                                                            id="country"
                                                            onChange={this._handleCountrySelect}
                                                            options={this.state.countryListOptions}
                                                            placeholder={PROPERTY.SELECT_INDUSTRY}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                    </TatvamCol>

                                                    <TatvamCol m={4}
                                                        className={(this.state.selectedCategory.value === "Competitor" || this.state.selectedCategory.value === "Tenants") ? "mb-1  mt-1 select-field" : "mb-1  mt-0 select-field"}>
                                                        <label htmlFor="state">{PROPERTY.VIEW_STATE}<span
                                                            style={{ color: 'red' }}> *</span></label>
                                                        <TatvamMultiSelect
                                                            classNamePrefix="react-select"
                                                            className={(this.state.inValidForm && this.state.selectedState.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                            value={this.state.selectedState}
                                                            name="state"
                                                            id="state"
                                                            onChange={this._handleStateSelect}
                                                            options={this.state.stateListOptions}
                                                            placeholder={PROPERTY.SELECT_INDUSTRY}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                    </TatvamCol>


                                                    <TatvamCol m={4} className="p-0 mt-0">
                                                        <label className="status-label pl-3">{PROPERTY.WEBSITE}</label>
                                                        <TatvamTextBox
                                                            className={"labelText mt-0 project_name select-ht "}
                                                            m={12}
                                                            name="website"
                                                            onChange={this._handleInput}
                                                            value={this.state.website}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            autoComplete="off"
                                                            maxLength={50}
                                                        />
                                                    </TatvamCol>
                                                </TatvamCol>
                                            )}

                                            {/* {(this.state.selectedCategory.value !== "Competitor" && this.state.selectedCategory.value !== "Tenants") && (
                                                <TatvamCol m={4} className="p-0">
                                                <label className="status-label pl-3" >{PROPERTY.EXECUTION_BATCH}</label>
                                                <TatvamTextBox
                                                    className="labelText project_name validate mb-0 select-ht"
                                                    m={12}
                                                    maxLength={30}
                                                    validate
                                                    id="execution_batch"
                                                    name="execution_batch"
                                                    onChange={this._handleInput}
                                                    title={TEXT_VALIDATION.EXECUTION_BATCH}
                                                    value={this.state.execution_batch}
                                                    disabled={this.state.applicationMode === APPLICATION_MODES.VIEW || this.state.isCorporate}
                                                    autoComplete="off"
                                                />
                                                </TatvamCol>
                                            )} */}
                                            <TatvamCol m={4} className="p-0">
                                                <label
                                                    className="status-label pl-3">{PROPERTY.SUBSCRIPTION_BATCH}</label>
                                                <TatvamTextBox
                                                    className="labelText project_name validate mb-0 select-ht"
                                                    m={12}
                                                    maxLength={30}
                                                    validate
                                                    id="inapp_subscription_batch"
                                                    name="inapp_subscription_batch"
                                                    onChange={this._handleInput}
                                                    value={this.state.inapp_subscription_batch}
                                                    disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                    autoComplete="off"
                                                />
                                            </TatvamCol>

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <TatvamCol m={4} className="p-0 mt-0">
                                                    <label className="status-label pl-3">{PROPERTY.MAX_CAPACITY}</label>
                                                    <TatvamTextBox
                                                        className={"labelText mt-0 project_name select-ht p-0 m-0"}
                                                        m={12}
                                                        pattern="^[0-9]{1,10}$"
                                                        name="max_capacity"
                                                        onChange={this._handleInput}
                                                        value={this.state.max_capacity}
                                                        disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        autoComplete="off"
                                                        maxLength={50}
                                                    />
                                                </TatvamCol>
                                            )}

                                            <TatvamCol m={4} className="mb-1 mt-0 select-field">
                                                <label htmlFor="database_name">{PROPERTY.DATABASE_NAME}<span
                                                    style={{ color: 'red' }}> *</span></label>
                                                <TatvamMultiSelect
                                                    classNamePrefix="react-select"
                                                    className={(this.state.inValidForm && (this.state.selectedDatabaseName === "" || this.state.selectedDatabaseName.label === "")) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                    value={this.state.selectedDatabaseName}
                                                    name="database_name"
                                                    id="database_name"
                                                    onChange={this._handleDatabaseNameChange}
                                                    options={this.state.DatabaseListOption}
                                                    placeholder={PROPERTY.SELECT_DATABASE_TYPE}
                                                    disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                />
                                            </TatvamCol>
                                        </TatvamCol>

                                        {/* InappFiels */}

                                        <div className="row m-0">
                                            <TatvamCol s={12} m={12} className="switch-wrapper hr">
                                                <Switch
                                                    id="is_inapp"
                                                    checked={this.state.is_inapp}
                                                    onClick={this._handleAppSelect}
                                                    offLabel={"Inapp"}
                                                    m={6}
                                                />

                                            </TatvamCol>

                                            {this.state.is_inapp && (!this.state.isCorporate) && (
                                                <Fragment>
                                                    <TatvamCol m={3} className="switch-wrapper pt-3 mb-2">
                                                        <Switch
                                                            id="is_translation"
                                                            checked={this.state.is_translation}
                                                            onChange={this._handleInput}
                                                            offLabel={PROPERTY.IS_TRANSLATION}
                                                            m={1.5}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                        <Switch
                                                            id="is_accuracy"
                                                            checked={this.state.is_accuracy}
                                                            onChange={this._handleInput}
                                                            offLabel={PROPERTY.IS_ACCURACY}
                                                            m={1.5}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                    </TatvamCol>

                                                </Fragment>
                                            )}


                                            {this.state.is_inapp &&
                                                <Fragment>
                                                    <TatvamCol m={3}
                                                        className={(this.state.selectedCategory.value === "Competitor" || this.state.selectedCategory.value === "Tenants" || this.state.isCorporate) ? "switch-wrapper pt-3 mb-2" : "switch-wrapper pt-3 mb-2"}>
                                                        <Switch
                                                            id="is_action_trackable"
                                                            checked={this.state.is_action_trackable}
                                                            onChange={this._handleInput}
                                                            offLabel={PROPERTY.IS_ACTION_TRACKABLE}
                                                            m={1.5}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                        <Switch
                                                            id="is_curse_word"
                                                            checked={this.state.is_curse_word}
                                                            onChange={this._handleInput}
                                                            offLabel={PROPERTY.IS_CURSE_WORD}
                                                            m={1.5}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                    </TatvamCol>
                                                    <TatvamCol m={3}
                                                        className={(this.state.selectedCategory.value === "Competitor" || this.state.selectedCategory.value === "Tenants" || this.state.isCorporate) ? "switch-wrapper pt-3 mb-2" : "switch-wrapper pt-3 mb-2"}>
                                                        <Switch
                                                            id="is_campaignable"
                                                            checked={this.state.is_campaignable}
                                                            onChange={this._handleInput}
                                                            offLabel={PROPERTY.IS_CAMPAIGNABLE}
                                                            m={2}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />

                                                        <Switch
                                                            id="is_response_trackable"
                                                            checked={this.state.is_response_trackable}
                                                            onChange={this._handleInput}
                                                            offLabel={PROPERTY.IS_RESPONSE_TRACKABLE}
                                                            m={2}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                        />
                                                    </TatvamCol>
                                                    <TatvamCol m={3} className={"switch-wrapper pt-3 mb-2"}>
                                                        {this.state.is_inapp && (!this.state.isCorporate) && (
                                                            <Switch
                                                                id="is_source_weightage"
                                                                checked={this.state.is_source_weightage}
                                                                onChange={this._handleInput}
                                                                offLabel={PROPERTY.IS_SOURCE_WEIGHTAGE}
                                                                m={2}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            />
                                                        )}
                                                        {
                                                            ((this.state.selectedCategory.value === "Live" ||
                                                                this.state.selectedCategory.value === "Demo" || this.state.selectedCategory.value === "Tenants") ||
                                                                (this.state.category === "Live" ||
                                                                    this.state.category === "Demo")) &&
                                                            (this.state.status === "Active" || this.state.selectedStatus.value === "Active") &&
                                                            !this.state.isCorporate &&
                                                            <Switch
                                                                id="is_chatbot"
                                                                checked={this.state.is_chatbot}
                                                                onChange={this._handleInput}
                                                                offLabel={PROPERTY.IS_CHATBOT}
                                                                m={2}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            />
                                                        }
                                                    </TatvamCol>

                                                    {/* {this.state.is_campaignable && (
                                                        <TatvamCol m={4} className="p-0">
                                                            <label className="status-label pl-3">{PROPERTY.SUPPORT_EMAIL}<span
                                                                style={{ color: 'red' }}> *</span></label>
                                                            <TatvamTextBox
                                                                className={(this.state.inValidForm && (this.state.is_campaignable && (!this.state.customer_support_email_id || this.state.customer_support_email_id === ""))) ? "labelText mt-0 project_name invalid select-ht p-0 m-0" : "labelText mt-0 project_name select-ht p-0 m-0"}
                                                                m={12}
                                                                name="customer_support_email_id"
                                                                id="customer_support_email_id"
                                                                onChange={this._handleInput}
                                                                value={this.state.customer_support_email_id}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                                autoComplete="off"
                                                                maxLength={255}
                                                            />
                                                        </TatvamCol>
                                                    )} */}

                                                    {(this.state.selectedCategory.value === "Competitor" || this.state.selectedCategory.value === "Demo" || this.state.selectedCategory.value === "Live" || this.state.selectedCategory.value === "Tenants") && (
                                                        <TatvamCol m={4} className="p-0">
                                                            <label
                                                                className="status-label pl-3">{PROPERTY.EXECUTION_BATCH}</label>
                                                            <TatvamTextBox
                                                                className="labelText project_name validate mb-0 select-ht"
                                                                m={12}
                                                                maxLength={30}
                                                                validate
                                                                id="execution_batch"
                                                                name="execution_batch"
                                                                onChange={this._handleInput}
                                                                title={TEXT_VALIDATION.EXECUTION_BATCH}
                                                                value={this.state.execution_batch}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW || this.state.isCorporate}
                                                                autoComplete="off"
                                                            />
                                                        </TatvamCol>
                                                    )}

                                                    {this.state.category !== "Competitor" && (
                                                        <TatvamCol m={4} className="col m4 xl4 mb-1 mt-0 select-field">
                                                            <label htmlFor="inapp_default_landing_page">{PROPERTY.DEFAULT_REPORT}<span
                                                                style={{ color: 'red' }}> *</span></label>
                                                            <TatvamMultiSelect
                                                                classNamePrefix="react-select"
                                                                className={(this.state.is_inapp && this.state.selectedDefaultReport.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                                value={this.state.selectedDefaultReport}
                                                                name="inapp_default_landing_page"
                                                                id="inapp_default_landing_page"
                                                                onChange={this._handleDefaultReportChange}
                                                                options={this.state.reportsList}
                                                                placeholder={PROPERTY.SELECT_DEFAULT_REPORT}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            />
                                                        </TatvamCol>
                                                    )}

                                                    {this.state.is_inapp && (!this.state.isCorporate) && (
                                                        <TatvamCol m={4} className="col m4 xl4 mb-1 mt-0 select-field">
                                                            <label htmlFor="rating_type">{PROPERTY.RATING_TYPE}<span
                                                                style={{ color: 'red' }}> *</span></label>
                                                            <TatvamMultiSelect
                                                                classNamePrefix="react-select"
                                                                className={(this.state.is_inapp && (this.state.selectedRatingType === "" || this.state.selectedRatingType.label === "")) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                                value={this.state.selectedRatingType}
                                                                name="rating_type"
                                                                id="rating_type"
                                                                onChange={this._handleRatingTypeChange}
                                                                options={this.state.ratingTypeListOption}
                                                                placeholder={PROPERTY.SELECT_RATING_TYPE}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            />
                                                        </TatvamCol>
                                                    )}

                                                </Fragment>

                                            }
                                        </div>

                                        {/* predicto fileds */}
                                        <div className="row m-0">
                                            <TatvamCol s={12} m={12} className="switch-wrapper hr">
                                                <Switch
                                                    id="is_predicto"
                                                    checked={this.state.is_predicto}
                                                    onClick={this._handleAppSelect}
                                                    offLabel={"Predicto"}
                                                    m={6}
                                                />
                                            </TatvamCol>

                                            {this.state.is_predicto &&
                                                <Fragment>
                                                    <TatvamCol m={4} className="p-0 mt-0">
                                                        <label
                                                            className="status-label">{PROPERTY.PREDICTION_BATCH}</label>
                                                        <TatvamTextBox
                                                            className="labelText project_name validate mb-0 select-ht"
                                                            m={12}
                                                            maxLength={30}
                                                            validate
                                                            id="predicto_batch"
                                                            name="predicto_batch"
                                                            onChange={this._handleInput}
                                                            value={this.state.predicto_batch}
                                                            autoComplete="off"
                                                        />
                                                    </TatvamCol>

                                                    <TatvamCol m={4} className="p-0 mt-0">
                                                        <label
                                                            className="status-label">{PROPERTY.PREDICTION_PERCENTAGE}</label>
                                                        <TatvamTextBox
                                                            className="labelText project_name validate mb-0 select-ht"
                                                            m={12}
                                                            maxLength={30}
                                                            validate
                                                            id="predicto_percentage"
                                                            name="predicto_percentage"
                                                            onChange={this._handleInput}
                                                            value={this.state.predicto_percentage}
                                                            autoComplete="off"
                                                        />
                                                    </TatvamCol>

                                                    <TatvamCol m={4} className="p-0">
                                                        <label
                                                            className="status-label pl-3">{PROPERTY.PREDICTO_SUBSCRIPTION_BATCH}</label>
                                                        <TatvamTextBox
                                                            className="labelText project_name validate mb-0 select-ht"
                                                            m={12}
                                                            maxLength={30}
                                                            validate
                                                            id="predicto_subscription_batch"
                                                            name="predicto_subscription_batch"
                                                            onChange={this._handleInput}
                                                            value={this.state.predicto_subscription_batch}
                                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            autoComplete="off"
                                                        />
                                                    </TatvamCol>

                                                    {this.state.category !== "Competitor" && this.state.category !== "Tenants" && (
                                                        <TatvamCol m={4} className="col m4 xl4 mb-1 mt-1 select-field">
                                                            <label htmlFor="predicto_default_landing_page">{PROPERTY.DEFAULT_REPORT}<span
                                                                style={{ color: 'red' }}> *</span></label>
                                                            <TatvamMultiSelect
                                                                classNamePrefix="react-select"
                                                                className={(this.state.is_predicto && this.state.selectedPredictoDefaultReport.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                                value={this.state.selectedPredictoDefaultReport}
                                                                name="predicto_default_landing_page"
                                                                id="predicto_default_landing_page"
                                                                onChange={this._handlePredictoDefaultReportChange}
                                                                options={this.state.predictoReportList}
                                                                placeholder={PROPERTY.SELECT_DEFAULT_REPORT}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            />
                                                        </TatvamCol>
                                                    )}
                                                </Fragment>
                                            }
                                        </div>

                                        <div className="row m-0">
                                            <TatvamCol s={12} m={12} className="switch-wrapper hr">
                                                <Switch
                                                    id="is_blms"
                                                    checked={this.state.is_blms}
                                                    onClick={this._handleAppSelect}
                                                    offLabel={"Blms"}
                                                    m={6}
                                                />
                                            </TatvamCol>
                                        </div>
                                    </TatvamCol>
                                    {/* form Button */}
                                    <TatvamCol s={12} className="p-0">
                                        {this.state.applicationMode !== APPLICATION_MODES.VIEW && (
                                            <Fragment>
                                                <TatvamCol s={12} m={6} className=" mt-2">
                                                    {!this.state.isValidDate && (
                                                        <p className="errorText">
                                                            {PROPERTY.START_DATE_SHOULD_BE_LESS_THAN_END_DATE}
                                                        </p>
                                                    )}
                                                    {!this.state.mandatoryFields && (
                                                        <p className="errorText">
                                                            {PROPERTY.ENTER_ALL_THE_MANDATORY_FIELDS}
                                                        </p>
                                                    )}

                                                </TatvamCol>

                                                <TatvamCol s={12} m={6}
                                                    className="buttonWrapper right valign-wrapper formRow">

                                                    <TatvamButton
                                                        waves="light"
                                                        className="mt-1 mr-3 btn_primary waves"
                                                        onClick={this._triggerDiscard}
                                                    >
                                                        {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                                            FORM_OPERATIONS.CANCEL : FORM_OPERATIONS.DISCARD}
                                                    </TatvamButton>

                                                    <TatvamButton
                                                        waves="light"
                                                        className=" mt-1 mr-1 btn_primary  "
                                                        disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true) && !this._handleCheckIsMandetoryFieldFilled())}
                                                    >
                                                        {FORM_OPERATIONS.SAVE}
                                                    </TatvamButton>
                                                </TatvamCol>
                                            </Fragment>
                                        )}
                                    </TatvamCol>
                                </form>
                            </Col>
                        </Row>
                    ) :
                        (
                            <Fragment>
                                <Row className="form-wrapper">
                                    <TatvamCol s={12} m={12} l={2} className="imageWrapper">
                                        <div className="left d-flex flex-colum image-uploaded mb-2">
                                            <img
                                                className="responsive-img"
                                                src={this.state.logo !== "" ? this.state.logo :
                                                    require("../../../../public/assets/images/default_customer.png")}
                                                alt="logo"
                                                title="Property Logo"
                                            />
                                        </div>
                                    </TatvamCol>
                                    <TatvamCol s={12} l={10} className="p-0 customerFormView">

                                        <TatvamCol s={12} m={12}
                                            className={this.state.category === "Competitor" || this.state.category === "Tenants" ? "mt-.75 " : "mt-.75"}>
                                            <div className="mt-0 col s12 m4">
                                                <span
                                                    className="property-label col s6">{PROPERTY.VIEW_PROPERTY_ID} : </span>
                                                <span className="col s6"> {this.state.id}</span>
                                            </div>

                                            <div className="mt-0 col s12 m4">
                                                <span
                                                    className="property-label col s6"> {PROPERTY.VIEW_PROPERTY_NAME} : </span>
                                                <span className="col s6 url-wrap"> {this.state.name} </span>
                                            </div>

                                            {this.state.category !== "Tenants" &&
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {PROPERTY.VIEW_INDUSTRY} : </span>
                                                    <span className="col s6"> {this.state.selectedIndustry.label}</span>
                                                </div>}

                                            {this.state.category === "Tenants" &&
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {PROPERTY.VIEW_INDUSTRY_CLASSIFICATION} : </span>
                                                    <span className="col s6"> {this.state.selectedIndustryClassification.label}</span>
                                                </div>}
                                        </TatvamCol>

                                        <TatvamCol s={12} m={12} className="mt-.75">
                                            {this.state.category !== "Tenants" &&
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {PROPERTY.VIEW_ADDITIONAL_INDUSTRY} : </span>
                                                    <span className="col s6">
                                                        <ul className="m-0">
                                                            {(this.props.customer && this.props.customer.additionalIndustry && this.props.customer.additionalIndustry.length > 0 &&
                                                                this.props.customer.additionalIndustry.map((addInd) => {
                                                                    return <span className="mt-0 mb-0"><li> {addInd}</li>  </span>
                                                                })
                                                            )}
                                                        </ul>
                                                    </span>
                                                </div>
                                            }

                                            {this.state.category === "Tenants" &&
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {"Parent Property"} : </span>
                                                    <span className="col s6"> {this.state.selectedParentProperty.label} </span>
                                                </div>}

                                            <div className="mt-0 col s12 m4">
                                                <span
                                                    className="property-label col s6"> {PROPERTY.VIEW_CATEGORY} : </span>
                                                <span className="col s6"> {this.state.selectedCategory.label} </span>
                                            </div>

                                            {this.state.category !== "Competitor" && this.state.category !== "Tenants" && (
                                                <Fragment>
                                                    <div className="mt-0 col s12 m4">
                                                        <span
                                                            className="property-label col s6"> {PROPERTY.VIEW_LICENCE_TYPE} : </span>
                                                        <span
                                                            className="col s6"> {this.state.selectedLicense.label}</span>
                                                    </div>
                                                </Fragment>
                                            )}

                                            {(this.state.category === "Competitor" || this.state.category === "Tenants") && (
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6">{PROPERTY.VIEW_STATUS} : </span>
                                                    <span className="col s6"> {this.state.status}</span>
                                                </div>
                                            )}

                                        </TatvamCol>

                                        <TatvamCol s={12} m={12} className="mt-.75">


                                            {this.state.category !== "Competitor" && this.state.category !== "Tenants" && (
                                                <div>
                                                    <div className="mt-0 col s12 m4">
                                                        <span
                                                            className="property-label col s6">{PROPERTY.VIEW_STATUS} : </span>
                                                        <span className="col s6"> {this.state.status}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.category !== "Competitor" && this.state.category !== "Tenants" && (
                                                <Fragment>
                                                    {/* <TatvamCol s={12}>
                        <span className="property-label licenseLabel mb-0 col s12 l2"> {PROPERTY.VIEW_LICENCE} </span>
                        </TatvamCol> */}
                                                    <div className="mt-0 col s12 m4">
                                                        <span
                                                            className="property-label col s6"> {PROPERTY.GROUP_INSTANCE} : </span>
                                                        <span
                                                            className="col s5"> {this.state.corporate ? this.state.corporate : APPLICATION_MODES.NO}</span>
                                                    </div>
                                                    <div className="mt-0 col s12 m4">
                                                        <div className="col s12 p-0">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.VIEW_LICENSE_START_DATE} : </span>
                                                            <span className="col s6"> {this.state.valid_from}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-0 col s12 m4">
                                                        <span
                                                            className="property-label col s6"> {PROPERTY.VIEW_LICENSE_END_DATE} : </span>
                                                        <span className="col s6"> {this.state.valid_upto}</span>
                                                    </div>
                                                    {this.state.parent_customer && this.state.parent_customer.length && (
                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.PARENT_PROPERTY} : </span>
                                                            <span className="col s6">
                                                                {this.state.parent_customer.map((data) => {
                                                                    if (this.props.customerList[data.id]) {
                                                                        return <span className="parent-property"
                                                                            onClick={() => this._goTo(data)}>{data.name} ,</span>;
                                                                    } else {
                                                                        return <span className="parent-property no-access">{data.name} </span>;
                                                                    }
                                                                }
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <div className="mt-0 col s12 m4">
                                                    <div className="col s12 p-0">
                                                        <span
                                                            className="property-label col s6"> {PROPERTY.VIEW_TIME_ZONE} : </span>
                                                        <span className="col s6"> {this.state.time_zone}</span>
                                                    </div>
                                                </div>)}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <div className="mt-0 col s12 m4 p-0">
                                                    <div className="col s12 p-0">
                                                        <span
                                                            className="property-label col s6 pr-3"> {PROPERTY.VIEW_ADDRESS} : </span>
                                                        <span className="input-field col s6 p-0">
                                                            <TatvamTextArea
                                                                m={"12 p-0 "}
                                                                name="address"
                                                                className={(this.state.inValidForm && !this.state.address) ? "labelText mb-0 validate invalid ht5" : "labelText mb-0 validate ht5 "}
                                                                value={this.state.address}
                                                                maxLength={250}
                                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>)}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {PROPERTY.VIEW_COUNTRY} : </span>
                                                    <span className="col s5"> {this.state.country}</span>
                                                </div>)}

                                            {this.state.selectedCategory.value !== "Tenants" && (
                                                <div className="mt-0 col s12 m4">
                                                    <span className="property-label col s6"> {PROPERTY.VIEW_STATE} : </span>
                                                    <span className="col s5"> {this.state.state}</span>
                                                </div>)}

                                            {this.state.category !== "Tenants" &&
                                                <div className="mt-0 col s12 m4">
                                                    <span className="property-label col s6"> {PROPERTY.WEBSITE} : </span>
                                                    <span className="col s5"> {this.state.website}</span>
                                                </div>}


                                            <div className="mt-0 col s12 m4">
                                                <span
                                                    className="property-label col s6"> {PROPERTY.SUBSCRIPTION_BATCH} : </span>
                                                <span className="col s6"> {this.state.inapp_subscription_batch}</span>
                                            </div>


                                            <div className="mt-0 col s12 m12 p-0">
                                                {this.state.category !== "Tenants" &&
                                                    <div className="mt-0 col s12 m4">
                                                        <span
                                                            className="property-label col s6"> {PROPERTY.MAX_CAPACITY} : </span>
                                                        <span className="col s5"> {this.state.max_capacity}</span>
                                                    </div>
                                                }

                                                <div className="mt-0 col s12 m4">
                                                    <span
                                                        className="property-label col s6"> {PROPERTY.DATABASE_NAME} : </span>
                                                    <span className="col s5"> {this.state.selectedDatabaseName.label}
                                                    </span>
                                                </div>
                                            </div>




                                            {/* Inapp fields */}
                                            <div className="row m-0">
                                                <div className="mt-0 col s12 m12 hr">
                                                    <span className="property-label col ">Inapp : </span>
                                                    <span
                                                        className="col "> {this.state.is_inapp ? "Enabled" : "Disabled"}</span>
                                                </div>
                                                {this.state.is_inapp &&
                                                    <Fragment>
                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.EXECUTION_BATCH} : </span>
                                                            <span
                                                                className="col s6"> {this.state.execution_batch}</span>
                                                        </div>

                                                        {!(this.state.isCorporate) && (
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6"> {PROPERTY.IS_TRANSLATION} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.is_translation ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                            </div>
                                                        )}

                                                        {!(this.state.isCorporate) && (
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6"> {PROPERTY.IS_ACCURACY} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.is_accuracy ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                            </div>
                                                        )}

                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.IS_ACTION_TRACKABLE} : </span>
                                                            <span
                                                                className="col s5"> {this.state.is_action_trackable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                        </div>

                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.IS_CURSE_WORD} : </span>
                                                            <span
                                                                className="col s5"> {this.state.is_curse_word ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                        </div>

                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.IS_CAMPAIGNABLE} : </span>
                                                            <span
                                                                className="col s5"> {this.state.is_campaignable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                        </div>

                                                        {/* <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.SUPPORT_EMAIL} : </span>
                                                            <span
                                                                className="col s6"> {this.state.customer_support_email_id}</span>
                                                        </div> */}

                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6 pl-0 pr-0"> {PROPERTY.IS_RESPONSE_TRACKABLE} : </span>
                                                            <span
                                                                className="col s5"> {this.state.is_response_trackable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                        </div>

                                                        {!(this.state.isCorporate) && (
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6 pl-0"> {PROPERTY.IS_SOURCE_WEIGHTAGE} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.is_source_weightage ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                            </div>
                                                        )}
                                                        {
                                                            ((this.state.selectedCategory.value === "Live" ||
                                                                this.state.selectedCategory.value === "Demo" || this.state.selectedCategory.value === "Tenants") ||
                                                                (this.state.category === "Live" ||
                                                                    this.state.category === "Demo" || this.state.category === "Tenants")) &&
                                                            (this.state.status === "Active" || this.state.selectedStatus.value === "Active") &&
                                                            !this.state.isCorporate &&
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6"> {PROPERTY.IS_CHATBOT} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.is_chatbot ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                                            </div>
                                                        }

                                                        {this.state.category !== "Competitor" && (
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6"> {PROPERTY.DEFAULT_REPORT} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.inapp_default_landing_page}</span>
                                                            </div>
                                                        )}

                                                        {!(this.state.isCorporate) && (
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6"> {PROPERTY.RATING_TYPE} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.selectedRatingType.label}</span>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                }
                                            </div>

                                            {/* predicto Fields */}
                                            <div className="row m-0">
                                                <div className="mt-0 col s12 m12 hr ">
                                                    <span className="property-label col ">Predicto : </span>
                                                    <span
                                                        className="col "> {this.state.is_predicto ? "Enabled" : "Disabled"}</span>
                                                </div>
                                                {this.state.is_predicto &&
                                                    <Fragment>
                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.PREDICTION_BATCH} : </span>
                                                            <span className="col s5"> {this.state.predicto_batch}</span>
                                                        </div>

                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.PREDICTION_PERCENTAGE} : </span>
                                                            <span
                                                                className="col s5"> {this.state.predicto_percentage}</span>
                                                        </div>

                                                        <div className="mt-0 col s12 m4">
                                                            <span
                                                                className="property-label col s6"> {PROPERTY.PREDICTO_SUBSCRIPTION_BATCH} : </span>
                                                            <span
                                                                className="col s5"> {this.state.predicto_subscription_batch}</span>
                                                        </div>

                                                        {this.state.category !== "Competitor" && this.state.category !== "Tenants" && (
                                                            <div className="mt-0 col s12 m4">
                                                                <span
                                                                    className="property-label col s6"> {PROPERTY.DEFAULT_REPORT} : </span>
                                                                <span
                                                                    className="col s5"> {this.state.predicto_default_landing_page}</span>
                                                            </div>
                                                        )}

                                                    </Fragment>
                                                }
                                            </div>

                                            {/* blms Fields */}
                                            <div className="row m-0">
                                                <div className="mt-0 col s12 m12 hr ">
                                                    <span className="property-label col ">Blms : </span>
                                                    <span
                                                        className="col "> {this.state.is_blms ? "Enabled" : "Disabled"}</span>
                                                </div>
                                            </div>
                                        </TatvamCol>

                                        <TatvamCol className="right">
                                            {this.props.customerEdit === APP_BOOLEANS.TRUE && (
                                                <TatvamButton
                                                    className="mt-1 CreateProjectPublish btn_primary "
                                                    name="EDIT"
                                                    onClick={this._handleAppMode}
                                                >
                                                    {FORM_OPERATIONS.EDIT}
                                                </TatvamButton>
                                            )}
                                        </TatvamCol>
                                    </TatvamCol>
                                </Row>
                            </Fragment>
                        )}
                </TatvamCol>


                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        isConfirmBox={this.state.isConfirmBox}
                        closeAlertBox={this._discard}
                        alert_information={this.state.alert_information}
                        isModalClose={this.state.isModalClose}
                    />
                )}

                {
                    this.state.imageCropModal && (
                        <TatvamModal
                            open={this.state.imageCropModal}
                            className="hideFooter"
                            header={this.state.title}
                            options={{ dismissible: false }}
                            fixedFooter={false}
                            children={(
                                <CropImageForm closeCropModal={this.closeCropModal} setImageData={this.setImageData} />
                            )}
                        />

                    )}
            </Fragment>
        );
    }
}

export default CustomerForm;
