const initialState = {
    list: [],
    error: {},
    dimensionIndustry:{}
};

const IndustryDimensionReducers = (state, action) => {

    if (state === null || state === undefined) {
        state = initialState;
    }

    switch (action.type) {
       
        case "GET_INDUSTRY_DIMENSION_ERROR":
            state = {
                ...state,
                dimensionIndustry: [],
                error: action.payload
            };
            return state;

        case "GET_INDUSTRY_DIMENSION_SUCCESS":
            state = {
                ...state,
                dimensionIndustry: action.payload
            };
            return state;
        case "DELETE_DIMENSION_INDUSTRY_SUCCESS":
                state = {
                    ...state,
                    dimensionIndustry: {
                        dimensionsGridValues:action.payload
                    }
                };
        return state;
        default:
            return state;
    }
};

export default IndustryDimensionReducers;
