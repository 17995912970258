const initialState = {
    list: [],
    error: {},
    industryTouchpoints:{}
};

const IndustryTouchpointsReducers = (state, action) => {

    if (state === null || state === undefined) {
        state = initialState;
    }

    switch (action.type) {
       
        case "GET_INDUSTRY_TOUCHPOINT_ERROR":
            state = {
                ...state,
                industryTouchpoints: [],
                error: action.payload
            };
            return state;

        case "GET_INDUSTRY_TOUCHPOINT_SUCCESS":
            state = {
                ...state,
                industryTouchpoints: action.payload
            };
            return state;
        case "DELETE_INDUSTRY_TOUCHPOINT_SUCCESS":
                state = {
                    ...state,
                    industryTouchpoints: {
                        touchpoints :action.payload
                    }
                };
        return state;
        default:
            return state;
    }
};

export default IndustryTouchpointsReducers;
