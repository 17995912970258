import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IndustryTouchpointsForm from "./industryTouchpointForm";
import { getIndustryStages } from "../../../actions";

const mapStateToProps = (state, ownProps) => (
    {
        industryStagesList: state.industryStages,

    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryStages
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(IndustryTouchpointsForm);
