import React, {Fragment} from 'react';

import Loader from 'react-loaders';
import {Col} from 'react-materialize';

import {ALERT_MESSAGES, APPLICATION_MODES, SOURCE_INDUSTRY,} from '../../../constants/appConstants';
import {TatvamButton, TatvamGrid, TatvamModal,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import SourceIndustryForm from '../sourceIndustryForm';

class SourceIndustry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            industryGridData: [{name: "Loading.."}],
            maxIndustryId: '',
            colDef: [
                {header: SOURCE_INDUSTRY.SOURCE_ID, field: "id", filterParams: {clearButton: true}, hide: true},
                {header: SOURCE_INDUSTRY.VIEW_NAME, field: "name", filterParams: {clearButton: true}},
                {header: SOURCE_INDUSTRY.STATUS, field: "status"}
            ],
            loader: true,
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idIndustry: data.id,
            name: data.name,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let industry_id = this.state.idIndustry;
            let industryData = {
                industry_id

            };
            this.props.actions.deleteIndustry(industryData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.props.actions.fetchAllIndustry().then(() => {

                    });

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }

            });

        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }

    componentDidMount() {
        this.setState({
            loader: true
        });
        this.props.actions.fetchAllIndustry().then(() => {
            this.setState({
                loader: false
            });
            if (this.props.sourceIndustryList && this.props.sourceIndustryList.industry) {
                this.setGridIndustryValues(this.props);
            } else {
                this.setState({
                    industryGridData: [],
                    maxIndustryId: 0
                });
            }
        });
        this._setSortColumnOrder(this.props);

    }

    _setSortColumnOrder = (thisProps) => {
        if (thisProps.history.location.state) {
            this.setState({
                customSortColumn: thisProps.history.location.state.sortColumnName,
                customSortOrder: thisProps.history.location.state.sortOrderName
            });
        }
    }

    componentWillReceiveProps(props) {
        if (props.sourceIndustryList && props.sourceIndustryList.industry) {
            this.setGridIndustryValues(props);
        } else {
            this.setState({
                industryGridData: [],
                maxIndustryId: 0
            });
        }

        this._setSortColumnOrder(props);
    }

    setGridIndustryValues = (thisProps) => {
        let industryData = thisProps.sourceIndustryList.industry;
        const fetchMaxIndustryId = industryData.reduce((prev, current) => (parseInt(prev.id) > parseInt(current.id)) ? prev : current, 1);
        this.setState({
            industryGridData: thisProps.sourceIndustryList.industry,
            maxIndustryId: fetchMaxIndustryId.id
        });
    };

    _onEditRowClick = (data) => {
        const industryData = data[0];
        this.props.history.push({
            pathname: "/industryDimensions",
            state: {
                industryData: industryData,
                sortColumnName: this.state.customSortColumn,
                sortOrderName: this.state.customSortOrder,
                returnPath: "/industry"
            }
        });
    };
    _openCreateModal = () => {
        this.setState({
            industryData: [],
            industryModal: true,
            modalTitle: "Add Industry",
            applicationMode: APPLICATION_MODES.CREATE
        });
    };

    closeModal = () => {
        this.setState({
            industryModal: false
        });
        this.props.actions.fetchAllIndustry();
    };

    handleStateChange(data) {
        this.setState({
            customSortColumn: data.customSortColumn,
            customSortOrder: data.customSortOrder
        });

    }

    render() {
        return (
            <Fragment>

                <div className="page_heading">
                    <h5>{SOURCE_INDUSTRY.SOURCE_MASTER}</h5>
                    <div className="dashboard-btn mt-1 valign-wrapper">
                        {/*{this.props.xPathCreate === "true" && (*/}
                        <TatvamButton className="btn btn_primary right " onClick={this._openCreateModal}
                                      title="Add Industry">
                            {SOURCE_INDUSTRY.ADD_INDUSTRY}
                        </TatvamButton>
                        {/*)*/}
                        {/*}*/}
                    </div>
                </div>
                <Col
                    s={12}
                    className={this.state.loader ? "valign-wrapper loader-overlay" : "hide"}
                >
                    {this.state.loader && <Loader type="ball-beat"/>}
                </Col>

                <div className="row  z-depth-4 h-75" ref="tableTop">
                    <TatvamGrid
                        handleStateChange={this.handleStateChange.bind(this)}
                        gridData={this.state.industryGridData}
                        colData={this.state.colDef}
                        enableRowClick
                        onRowSelect={this._onEditRowClick}
                        enablePagination
                        enableDeleteColumn={true}
                        onRowDelete={this._handleDeleteConfirmation}
                        sortColumnName={this.state.customSortColumn}
                        sortOrder={this.state.customSortOrder}
                    />
                </div>

                {this.state.industryModal && (
                    <TatvamModal
                        open={this.state.industryModal}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <SourceIndustryForm
                                formData={this.state.industryData}
                                closeModal={this.closeModal}
                                applicationMode={this.state.applicationMode}
                                maxId={this.state.maxIndustryId}
                                history={this.props.history}
                            />
                        )}
                    />
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default SourceIndustry;
