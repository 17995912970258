import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetIndustryTouchpointsSuccess: (payload) => ({
        type: "GET_INDUSTRY_TOUCHPOINT_SUCCESS",
        payload
    }),
    GetIndustryTouchpointsError: (payload) => ({
        type: "GET_INDUSTRY_TOUCHPOINT_ERROR",
        payload
    }),
    DeleteIndustryTouchpointsSuccess: (payload) => ({
        type: "DELETE_INDUSTRY_TOUCHPOINT_SUCCESS",
        payload
    }),
    DeleteIndustryTouchpointsError: (payload) => ({
        type: "DELETE_INDUSTRY_TOUCHPOINT_ERROR",
        payload
    }),
};

export const getIndustryTouchpoints = (industryName) => (dispatch, getState) => {
    const state = getState();
    let industryTouchpoints = state.industryTouchpoints;

    const url = apiUrl.BASE_URL + apiUrl.GET_INDUSTRY_TOUCHPOINTS.format(industryName);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                industryTouchpoints = {
                    ...industryTouchpoints,
                    touchpoints: response.data.data
                };
                dispatch(actions.GetIndustryTouchpointsSuccess(industryTouchpoints));
                return response.data.data;
            }

            dispatch(actions.GetIndustryTouchpointsError(response.message));
            return response.data.data;
        })
        .catch(error => {
            dispatch(actions.GetIndustryTouchpointsError(error.message));
            return Promise.resolve(error);
        });
};

export const importIndustryTouchpoints = (userData) => (dispatch) => {
    let formData = new FormData();
    formData.append("industryStages", userData.currentFile);
    const url = apiUrl.BASE_URL + apiUrl.IMPORT_INDUSTRY_TOUCHPOINT.format(userData.industryName);

    return axios
        .post(url, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;

        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const deleteIndustryTouchpoint = (industryTouchpoint) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = industryTouchpoint

    const url = apiUrl.BASE_URL + apiUrl.DELETE_INDUSTRY_TOUCHPOINT.format(industryTouchpoint.id);

    return axios
        .delete(url, payload)
        .then(response => {

            if (response.status === 200) {
           
                const updatedTouchpointList =  store.industryTouchpoints.industryTouchpoints.touchpoints.filter(stage => stage.id !== industryTouchpoint.id);

                dispatch(actions.DeleteIndustryTouchpointsSuccess(updatedTouchpointList));
                return response;
            }


            dispatch(actions.DeleteIndustryTouchpointsError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteIndustryTouchpointsError(error.message));
            return Promise.resolve(error.response);
        });
};