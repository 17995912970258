import {combineReducers} from 'redux';

import applicationName from './applicationNameReducer';
import auth from './authReducer';
import category from './categoryReducer';
import childProperties from './childPropertyReducer';
import commentType from './commentTypeReducer';
import customerCategory from './customerCategoryReducer';
import customer from './customerReducer';
import customerResponse from './customerReponseReducer';
import error from './errorReducer';
import fileFolderCategory from './fileUploadReducer';
import globalFilter from './globalFilterReducer';
import industryDimensionValue from './industryDimensionValueReducer';
import industry from './industryReducer';
import operations from './operationsReducer';
import reports from './reportsReducer';
import sources from './sourceReducer';
import states from './statesReducer';
import subscriptionReports from './subscriptionsReportsReducer';
import tagTreatment from './tagTreatmentReducer';
import timeZone from './timeZoneReducer';
import users from './userReducer';
import userType from './userTypeReducer';
import industryStages from './industryStagesReducer';
import industryTouchpoints from './industryTouchpointReducer';
import dimensionIndustry from './dimensionIndustryReducer';

const appReducer = combineReducers({
    auth,
    customer,
    customerCategory,
    industry,
    operations,
    reports,
    users,
    sources,
    error,
    category,
    commentType,
    industryDimensionValue,
    timeZone,
    tagTreatment,
    userType,
    subscriptionReports,
    childProperties,
    fileFolderCategory,
    states,
    globalFilter,
    customerResponse,
    applicationName,
    industryStages,
    industryTouchpoints,
    dimensionIndustry
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGNOUT_SUCCESS') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
