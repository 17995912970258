import React, { Component, Fragment } from "react";
import { ALERT_MESSAGES, APP_BOOLEANS, APPLICATION_MODES, STAGES_GRID } from "../../../../../constants/appConstants";
import { TatvamButton, TatvamGrid, TatvamModal, TatvamCol } from "../../../../functionalComponents";
import IndustryStageImport from "../../../industryStageImport";
import { trimCapitalize } from "../../../../../utils/stringUtil";
import { withRouter } from "react-router-dom";
import AlertBox from "../../../alertBox";
import IndustryStageForm from "../../../industryStageForm";
class IndustryStages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colDef: [
                {
                    headerName: STAGES_GRID.SEQ_NO,
                    field: "sequence_no",
                    filterParams: {clearButton: true},
                    width: 225
                },
                {
                headerName: STAGES_GRID.STAGE_NAME,
                field: "stages",
                filterParams: {clearButton: true},
                width: 225
            },
                {
                    headerName: STAGES_GRID.STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    width: 227
                },
            ],
            isModalOpen: false,
            loader: true,
            showImportModal: false
        }
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            id: data.id,
            idStage: data.id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };

    
    _deleteRow = (data) => {
        if (data === "ok") {
            let stage_id = this.state.id;
            let id = this.state.id;
            let stageDeleteData = {
                stage_id,
                id
            };
            this.props.actions.deleteIndustryStage(stageDeleteData).then((response) => {
                this.setState({
                    loader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    };

    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _openViewModal = (data) => {
        this.setState({
           stageData: data[0],
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true,
            loader: true
        });
    };

    componentDidMount() {
        let industryStageGridData = [];

        if (this.props.history.location.state.industryData.name) {
            this.setState({
                loader: true
            });

            this.props.actions.getIndustryStages(this.props.history.location.state.industryData.name).then(() => {

                this.setState({
                    loader: false
                });

            });
        }
        
        if (this.props.industryStagesList.industryStages.stages) {
            this.props.industryStagesList.industryStages.stages.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        industryStageGridData.push(data);
                    }
                }
            });
        }

        this.setState({
            industryStageGridData,
        }, () => {

            this.setActiveCount();
        });

    }

    setActiveCount = () => {
        const gridData = this.state.industryStageGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.statusCount(noOfActive.length,gridData.length);
    };

    componentWillReceiveProps(props) {
        let industryStageGridData = [];
        if (props.industryStagesList.industryStages.stages) {
            props.industryStagesList.industryStages.stages.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        industryStageGridData.push(data);
                    }

                }
            });
        }
        this.setState({
            industryStageGridData,
        }, () => {

            this.setActiveCount();
        });
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    _importStages = () => {
        this.setState({
            modalTitle: "",
            showImportModal: true
        });
    }

    closeImportModal = () => {
        this.setState({
            showImportModal: false
        });
    };

    render() {
        return (
            <Fragment>
                 <TatvamButton className="mt-1 ml-2 right ad-vis-btn btn_primary mr-1"
                                      onClick={this._importStages}
                                      title="Import Industry stages">
                            <i className="large material-icons">  import_contacts  </i>
                        </TatvamButton>


                        {
                            this.state.industryStageGridData && this.state.industryStageGridData.length > 0 ? (
                                <TatvamCol s={12} className="grid-data-area">
                                    <TatvamGrid
                                        gridData={this.state.industryStageGridData}
                                        colData={this.state.colDef}
                                        rowSelection="multiple"
                                        onRowSelect={this._openViewModal}
                                        enableDeleteColumn={true}
                                        onRowDelete={this._handleDeleteConfirmation}
                                    />
                                </TatvamCol>
                            ) : this.state.loader ? (
                                <TatvamCol s={12} className="grid-empty-area">
                                    <h5>Loading...</h5>
                                </TatvamCol>
                            ) : (
                                <TatvamCol s={12} className="grid-empty-area">
                                    <h5>No Stages available</h5>
                                </TatvamCol>
                            )
                        }


                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <IndustryStageForm
                                applicationMode={this.state.applicationMode}
                                // stageId={this.props.stageId}
                                closeModal={this.closeModal}
                                stageData={this.state.stageData}
                                nonEditable={this.state.nonEditable}
                            />
                        )}
                    />
                )
                }

                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}


                    {this.state.showImportModal && (
                    <TatvamModal
                        open={this.state.showImportModal}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <IndustryStageImport
                                closeModal={this.closeImportModal}
                                industryName={this.props.industryName}
                            />
                        )}
                    />
                )
                }


                </Fragment>
        )
    }
}

export default withRouter(IndustryStages);